import { Slide, toast } from "react-toastify";

export const notify = (toastContent) =>
  toast.info(toastContent, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Slide,
  });
