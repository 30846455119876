import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { Card } from "primereact/card";
import Select from "../Select";
import i18n from "../../i18n/i18n";
import ReactCountryDropdown from "react-country-dropdown";
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";
import { useTranslation } from "react-i18next";
import {
  fractions,
  languages,
  curvatureUnits,
  prismUnits,
  lensOrientations,
} from "../../constants/constants";
import SelectButtons from "../SelectButton";
import { AppContext } from "../../App";
import { findFraction } from "../../helpers/settingsHelpers";
import { cylinderNotations } from "../../constants/constants";
import countries from "../../helpers/countries.json";
import { manufacturers } from "../../package/helpers/manufacturers";
import { Button } from "primereact/button";
import PWAPrompt from "react-ios-pwa-prompt";
import IconUrl from "../../assets/logo192.png";

const Settings = () => {
  const {
    setFraction,
    cylinderNotation,
    setCylinderNotation,
    curvatureUnit,
    setCurvatureUnit,
    selectedCountry,
    setSelectedCountry,
    prismUnit,
    setPrismUnit,
    lensOrientation,
    setLensOrientation,
    manufacturer: chosenManufacturer,
    setManufacturer,
    filteredManufacturersList,
    setFilteredManufacturersList,
  } = useContext(AppContext);

  const { t } = useTranslation();

  const deferredPromptRef = useRef(null);
  const [showPwaPrompt, setShowPwaPrompt] = useState(false);
  const [showInstallCard, setShowInstallCard] = useState(false);

  useEffect(() => {
    if (!selectedCountry) return;
    setFilteredManufacturersList(
      manufacturers.filter(
        (manufacturer) =>
          manufacturer.categories.includes("Contact lenses") &&
          manufacturer.countries.includes(selectedCountry.toLowerCase())
      )
    );
  }, [selectedCountry, setFilteredManufacturersList]);

  const initialLanguage =
    Object.keys(languages).find((key) => languages[key] === i18n.language) ||
    "English";

  const [language, setLanguage] = useState(initialLanguage);
  const [selectedCountryName, setSelectedCountryName] = useState(
    countries.find((country) => country.alpha2Code === selectedCountry)?.name
  );

  const storedFraction = localStorage.getItem("fraction");
  const storedFractionValue = storedFraction
    ? JSON.parse(storedFraction)
    : null;
  const fractionValue = storedFractionValue
    ? storedFractionValue.formLabel
    : "";

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      deferredPromptRef.current = e;
      setShowInstallCard(true);
    };

    const checkPWAInstalled = async () => {
      if ("getInstalledRelatedApps" in navigator) {
        try {
          const relatedApps = await navigator.getInstalledRelatedApps();
          return relatedApps.some(
            (app) =>
              app.platform === "web" && app.url === window.location.origin
          );
        } catch (error) {
          console.error("Error checking installed related apps:", error);
          return false;
        }
      }
      return false;
    };

    const setupBeforeInstallPrompt = async () => {
      if ("onbeforeinstallprompt" in window) {
        const isInstalled = await checkPWAInstalled();

        if (!isInstalled) {
          window.addEventListener(
            "beforeinstallprompt",
            handleBeforeInstallPrompt
          );
        } else {
          setShowInstallCard(false);
        }
      } else {
        setShowInstallCard(true);
      }
    };

    setupBeforeInstallPrompt();

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleLanguageChange = useCallback((e) => {
    setLanguage(e.value);
    i18n.changeLanguage(languages[e.value]);
  }, []);

  const handleOrientationChange = useCallback(
    (e) => {
      if (e.value === null) return;
      setLensOrientation(e.value);
      localStorage.setItem("lens orientation", e.value);
    },
    [setLensOrientation]
  );

  const handleLensNotationChange = useCallback(
    (e) => {
      if (e.value === null) return;
      setCylinderNotation(e.value);
      localStorage.setItem("cylinder notation", e.value);
    },
    [setCylinderNotation]
  );

  const handleFractionChange = useCallback(
    (e) => {
      if (e.value === null) return;
      const selectedFraction = findFraction(e.value);
      setFraction(selectedFraction);
      try {
        const fractionString = JSON.stringify(selectedFraction);
        localStorage.setItem("fraction", fractionString);
      } catch (error) {
        console.error(
          "Failed to stringify fraction or store it in localStorage:",
          error
        );
      }
    },
    [setFraction]
  );

  const handleCurvatureUnitChange = useCallback(
    (e) => {
      if (e.value === null) return;
      setCurvatureUnit(e.value);
      localStorage.setItem("curvature unit", e.value);
    },
    [setCurvatureUnit]
  );

  const handlePrismUnitChange = useCallback(
    (e) => {
      if (e.value === null) return;
      setPrismUnit(e.value);
      localStorage.setItem("prism unit", e.value);
    },
    [setPrismUnit]
  );

  const handleCountryChange = useCallback(
    (country) => {
      localStorage.setItem("country", country.code);
      setSelectedCountry(country.code);
      setSelectedCountryName(country.name);
    },
    [setSelectedCountry]
  );

  const handleManufacturerChange = useCallback(
    (e) => {
      localStorage.setItem("manufacturer", e.value);
      setManufacturer(e.value);
    },
    [setManufacturer]
  );

  const onArrowClick = useCallback(() => {
    const element = document.querySelector("._dropdown_1c03y_15");
    if (element) {
      element.click();
    }
  }, []);

  const handleSafariInstall = useCallback(() => {
    setShowPwaPrompt(true);
  }, []);

  const handleClick = useCallback(() => {
    if (deferredPromptRef.current) {
      deferredPromptRef.current.prompt();
      deferredPromptRef.current.userChoice
        .then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
          } else {
          }
          deferredPromptRef.current = null;
        })
        .catch((err) => {
          console.error("Error during prompt:", err);
        });
    } else {
      handleSafariInstall();
    }
  }, [handleSafariInstall]);

  const handleClose = () => {
    setTimeout(() => {
      setShowPwaPrompt(false);
    }, 1000);
  };

  return (
    <div className="flex gap-2 flex-wrap">
      <Card title={t("Language")} className="col-fixed setting-item">
        <Select
          options={Object.keys(languages).map((lang) => ({
            label: t(lang),
            value: lang,
          }))}
          value={language}
          onChange={handleLanguageChange}
          className="w-18rem no-focus"
        />
      </Card>
      <Card title={t("Country")} className="col-fixed setting-item">
        <div className="relative w-max">
          <ReactCountryDropdown
            onSelect={handleCountryChange}
            defaultCountry={selectedCountry || ""}
          />
          <div className="absolute cursor-pointer arrow" onClick={onArrowClick}>
            <ArrowIcon />
          </div>
          <div
            className="absolute placeholder-container overflow-hidden text-overflow-ellipsis cursor-pointer white-space-nowrap"
            onClick={onArrowClick}
          >
            <p className="placeholder">{selectedCountryName}</p>
          </div>
        </div>
      </Card>
      <Card title="Contact lens orientation" className="col-fixed setting-item">
        <Select
          options={lensOrientations}
          value={lensOrientation}
          onChange={handleOrientationChange}
          className="w-18rem no-focus"
        />
      </Card>
      <Card title={t("Diopter rounding")} className="col-fixed setting-item">
        <SelectButtons
          options={fractions.flatMap((frac) => frac.formLabel)}
          onChange={handleFractionChange}
          value={fractionValue}
        />
      </Card>
      <Card title={t("Cylinder notation")} className="col-fixed setting-item">
        <SelectButtons
          options={Object.keys(cylinderNotations)}
          onChange={handleLensNotationChange}
          value={cylinderNotation}
        />
      </Card>
      <Card title={t("Curvature unit")} className="col-fixed setting-item">
        <SelectButtons
          options={curvatureUnits}
          onChange={handleCurvatureUnitChange}
          value={curvatureUnit}
        />
      </Card>
      <Card title={t("Prism unit")} className="col-fixed setting-item">
        <SelectButtons
          options={prismUnits}
          onChange={handlePrismUnitChange}
          value={prismUnit}
        />
      </Card>
      <Card
        title="Default RGP lens manufacturer"
        className="col-fixed setting-item"
      >
        <Select
          options={filteredManufacturersList.map(
            (manufacturer) => manufacturer.manufacturer
          )}
          value={
            filteredManufacturersList.find(
              (manufacturer) => manufacturer.manufacturer === chosenManufacturer
            )
              ? chosenManufacturer
              : filteredManufacturersList[0]?.manufacturer
          }
          onChange={handleManufacturerChange}
          className="w-18rem no-focus"
        />
      </Card>
      {showInstallCard && (
        <Card
          className="col-fixed setting-item"
          id="install-banner"
          title={
            <div className="flex align-items-center gap-3">
              <i className="far fa-download fa-lg" />
              <p>Install the Lens calculator to your device.</p>
            </div>
          }
        >
          <div className="flex flex-column gap-3">
            <div className="flex gap-2">
              <Button
                className="text-sm font-bold dark-button"
                type="button"
                id="install-button"
                onClick={handleClick}
              >
                Install App
              </Button>
            </div>
          </div>
        </Card>
      )}
      <PWAPrompt
        isShown={showPwaPrompt}
        onClose={handleClose}
        copyTitle="Install the Lens calculator to your device."
        appIconPath={IconUrl}
      />
    </div>
  );
};

export default Settings;
