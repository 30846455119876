export const en = {
  English: "English",
  German: "German",
  Language: "Language",
  Settings: "Settings",
  Country: "Country",
  AppTitle: "Lens calculator",
  Sphere: "Sphere",
  Cylinder: "Cylinder",
  Axis: "Axis",
  "Section 1": "Section 1",
  "Section 2": "Section 2",
  "Vertex old": "Vertex old",
  "Vertex new": "Vertex new",
  Calculate: "Calculate",
  Vertex: "Vertex",
  "Rotation & Vertex": "Rotation & Vertex",
  "Lens power": "Lens power",
  Clear: "Clear",
  "Cylinder notation": "Cylinder notation",
  Cyl: "Cyl",
  "Diopter rounding": "Diopter rounding",
  "Curvature unit": "Curvature unit",
  "Prism notation": "Prism notation",
  Degrees: "Degrees",
  Rounding: "Rounding",
  Directions: "Directions",
  "Prism unit": "Prism unit",
  OD: "OD",
  OS: "OS",
  "Over-refraction": "Over-refraction",
  "New parameters": "New parameters",
  "Lens parameters": "Lens parameters",
  "Contact lens orientation": "Contact lens orientation",
  Rotation: "Rotation",
  "Lens rotation": " Lens rotation",
  Stabilisation: "Stabilisation",
  "Stabilisation (TABO)": "Stabilisation (TABO)",
  toastInfo: "Please enter values for calculation.",
  menuItems: {
    "Contact lenses": "Contact lenses",
    "Spectacle optics": "Spectacle optics",
    "General optics": "General optics",
    "Low Vision": "Low Vision",
    Myopia: "Myopia",
    RGP: "RGP Lens Calculation",
    "Vertex conversion": "Vertex conversion",
    "Soft lens": "Soft Lens Calculation",
    "⁠⁠Combine prism": "⁠⁠Combine prism",
    "Resolve prism": "Resolve prism",
  },
  Out: "Out",
  In: "In",
  Up: "Up",
  Down: "Down",
  LensTooltip: "You can rotate the lens by simply clicking on it.",
};
