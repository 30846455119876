import { check2Radius } from "../helpers/check.js";
import { defaultFraction, nAir, nTears } from "../helpers/constant.js";
import { formatSpheroCylinder } from "../helpers/format.js";
import { calculateSections, calculateSpheroCylinder } from "./diopter.js";
import { calculateDiopter2matrix, calculateInducedAstigmatism, calculateLensAir2Eye, calculateMatrix2diopter, calculateMatrixSum } from "./rgphelper.js";
import { calculateLars } from "./softlens.js";
import { calculateVertex } from "./vertex.js";

/********************************************************************
 * Function to calculate RGP
 ********************************************************************/
export function calculateRGP({
  r1Old,
  r2Old,
  sphereOld = 0,
  cylinderOld = 0,
  axisOld = 0,
  nOld,
  rotation = 0,
  sphereAR = 0,
  cylinderAR = 0,
  axisAR = 0,
  vertex = 0,
  r1New,
  r2New,
  nNew,
  fraction = defaultFraction,
  sign = "",
}) {
  const radiusOld = check2Radius({ r1: r1Old, r2: r2Old });
  const radiusNew = check2Radius({ r1: r1New, r2: r2New });

  const inducedAirOld = calculateInducedAstigmatism({
    r1: radiusOld.r1,
    r2: radiusOld.r2,
    n1: nTears,
    n2: nOld,
  });

  const matrixOld = calculateDiopter2matrix({
    sphere1: sphereOld,
    cylinder1: cylinderOld,
    axis1: axisOld,
    sphere2: inducedAirOld.sphere,
    cylinder2: inducedAirOld.cylinder,
    axis2: inducedAirOld.axis,
  });

  const vertexNew = calculateVertex({
    sphere: sphereAR,
    cylinder: cylinderAR,
    axis: axisAR,
    vertexOld: vertex,
    vertexNew: 0,
  });

  const axisLars = calculateLars({ axis: axisAR, rotation });

  const sphereEye = ((1 - nTears) / Math.pow(radiusNew.r1, 2)) * (radiusOld.r1 - radiusNew.r1) * 1000;
  const inducedEye = calculateInducedAstigmatism({
    r1: radiusNew.r1,
    r2: radiusNew.r2,
    n1: nNew,
    n2: nTears,
  });

  const matrixEye = calculateDiopter2matrix({
    sphere1: vertexNew.sphere,
    cylinder1: vertexNew.cylinder,
    axis1: axisLars,
    sphere2: sphereEye,
    cylinder2: inducedEye.cylinder,
    axis2: inducedEye.axis,
  });

  const resultEye = calculateMatrixSum({
    Fx1: matrixOld.Fx,
    Fx2: matrixEye.Fx,
    Fy1: matrixOld.Fy,
    Fy2: matrixEye.Fy,
    Fxy1: matrixOld.Fxy,
    Fxy2: matrixEye.Fxy,
  });

  const sectionsEye = calculateSections({
    sphere: resultEye.sphere,
    cylinder: resultEye.cylinder,
  });

  const inducedAir = calculateInducedAstigmatism({
    r1: radiusNew.r1,
    r2: radiusNew.r2,
    n1: nAir,
    n2: nNew,
  });

  const matrixAir = calculateDiopter2matrix({
    sphere1: resultEye.sphere,
    cylinder1: resultEye.cylinder,
    axis1: resultEye.axis,
    sphere2: inducedAir.sphere,
    cylinder2: inducedAir.cylinder,
    axis2: inducedAir.axis,
  });

  const resultAir = calculateMatrix2diopter({
    Fx: matrixAir.Fx,
    Fy: matrixAir.Fy,
    Fxy: matrixAir.Fxy,
  });

  const sectionsAir = calculateSections({
    sphere: resultAir.sphere,
    cylinder: resultAir.cylinder,
  });

  const formatEye = formatSpheroCylinder({
    sphere: resultEye.sphere,
    cylinder: resultEye.cylinder,
    axis: resultEye.axis,
    fraction,
    sign,
  });

  const formatAir = formatSpheroCylinder({
    sphere: resultAir.sphere,
    cylinder: resultAir.cylinder,
    axis: resultAir.axis,
    fraction,
    sign,
  });

  return {
    eyeSphere: resultEye.sphere,
    eyeCylinder: resultEye.cylinder,
    eyeAxis: resultEye.axis,
    airSphere: resultAir.sphere,
    airCylinder: resultAir.cylinder,
    airAxis: resultAir.axis,
    eyeSection1: sectionsEye.section1,
    eyeSection2: sectionsEye.section2,
    airSection1: sectionsAir.section1,
    airSection2: sectionsAir.section2,
    eyeFormattedSphere: formatEye.sphere,
    eyeFormattedCylinder: formatEye.cylinder,
    eyeFormattedAxis: formatEye.axis,
    airFormattedSphere: formatAir.sphere,
    airFormattedCylinder: formatAir.cylinder,
    airFormattedAxis: formatAir.axis,
  };
}

/********************************************************************
 * Function to calculate RGP with Air sections
 ********************************************************************/
export function calculateRGPAirSections({
  r1Old,
  r2Old,
  section1Old,
  section2Old,
  axisOld = 0,
  nOld,
  rotation = 0,
  sphereAR = 0,
  cylinderAR = 0,
  axisAR = 0,
  vertex = 0,
  r1New,
  r2New,
  nNew,
  fraction = defaultFraction,
  sign = "",
}) {
  const sections = calculateSpheroCylinder({
    section1: section1Old,
    section2: section2Old,
    axis1: axisOld,
  });

  const parameters = calculateLensAir2Eye({
    r1: r1Old,
    r2: r2Old,
    sphere: sections.sphere,
    cylinder: sections.cylinder,
    axis: sections.axis,
    n1: nOld,
    n2: nAir,
  });

  return calculateRGP({
    r1Old,
    r2Old,
    sphereOld: parameters.sphere,
    cylinderOld: parameters.cylinder,
    axisOld: parameters.axis,
    nOld,
    rotation,
    sphereAR,
    cylinderAR,
    axisAR,
    vertex,
    r1New,
    r2New,
    nNew,
    fraction,
    sign,
  });
}

/********************************************************************
 * Function to calculate RGP with Eye sections
 ********************************************************************/
export function calculateRGPEyeSections({
  r1Old,
  r2Old,
  section1Old,
  section2Old,
  axisOld = 0,
  nOld,
  rotation = 0,
  sphereAR = 0,
  cylinderAR = 0,
  axisAR = 0,
  vertex = 0,
  r1New,
  r2New,
  nNew,
  fraction = defaultFraction,
  sign = "",
}) {
  const sections = calculateSpheroCylinder({
    section1: section1Old,
    section2: section2Old,
    axis1: axisOld,
  });

  return calculateRGP({
    r1Old,
    r2Old,
    sphereOld: sections.sphere,
    cylinderOld: sections.cylinder,
    axisOld: sections.axis,
    nOld,
    rotation,
    sphereAR,
    cylinderAR,
    axisAR,
    vertex,
    r1New,
    r2New,
    nNew,
    fraction,
    sign,
  });
}

/********************************************************************
 * Function to calculate RGP with Air SpheroCylinder
 ********************************************************************/
export function calculateRGPAirSpheroCylinder({
  r1Old,
  r2Old,
  sphereOld = 0,
  cylinderOld = 0,
  axisOld = 0,
  nOld,
  rotation = 0,
  sphereAR = 0,
  cylinderAR = 0,
  axisAR = 0,
  vertex = 0,
  r1New,
  r2New,
  nNew,
  fraction = defaultFraction,
  sign = "",
}) {
  const parameters = calculateLensAir2Eye({
    r1: r1Old,
    r2: r2Old,
    sphere: sphereOld,
    cylinder: cylinderOld,
    axis: axisOld,
    n1: nOld,
    n2: nAir,
  });

  return calculateRGP({
    r1Old,
    r2Old,
    sphereOld: parameters.sphere,
    cylinderOld: parameters.cylinder,
    axisOld: parameters.axis,
    nOld,
    rotation,
    sphereAR,
    cylinderAR,
    axisAR,
    vertex,
    r1New,
    r2New,
    nNew,
    fraction,
    sign,
  });
}