import { checkAxis } from './check.js';

/********************************************************************
 * Convert degrees to radians
 ********************************************************************/
export function deg2rad(degree) {
    return (degree * Math.PI) / 180;
}

/********************************************************************
 * Convert radians to degrees
 ********************************************************************/
export function rad2deg(radian) {
    return (radian * 180) / Math.PI;
}

/********************************************************************
 * Convert diopters to millimeters
 ********************************************************************/
export function diopter2mm(diopter) {
    return 337.5 / diopter;
}

/********************************************************************
 * Convert millimeters to diopters
 ********************************************************************/
export function mm2diopter(mm) {
    return 337.5 / mm;
}

/********************************************************************
 * Convert direction to numerical degree
 ********************************************************************/
export function convertDirectionToDegree(direction, eye) {
    const baseMapping = {
        OD: { in: 0, out: 180, up: 90, down: 270 },
        OS: { in: 180, out: 0, up: 90, down: 270 }
    };

    if (typeof direction === 'string') {
        return baseMapping[eye][direction.toLowerCase()];
    }

    return direction;
}

/********************************************************************
 * Convert numerical degree to direction
 ********************************************************************/
export function convertDegreeToDirection(degree, eye) {
    const baseMapping = {
        OD: { 0: 'in', 180: 'out', 90: 'up', 270: 'down' },
        OS: { 180: 'in', 0: 'out', 90: 'up', 270: 'down' }
    };

    return baseMapping[eye][degree];
}

/********************************************************************
 * Convert base direction for OD
 ********************************************************************/
export function convertBaseForOD(base, x, y) {
    if (x < 0 && y >= 0) return 180 - base;
    if (x < 0 && y < 0) return 180 + base;
    if (x >= 0 && y < 0) return 360 - base;
    return base;
  }
  
  /********************************************************************
   * Convert base direction for OS
   ********************************************************************/
  export function convertBaseForOS(base, x, y) {
    const adjustedBase = 180 - base;
    if (x < 0 && y >= 0) return 180 - adjustedBase;
    if (x < 0 && y < 0) return 180 + adjustedBase;
    if (x >= 0 && y < 0) return 360 - adjustedBase;
    return adjustedBase;
  }

/********************************************************************
 * Convert spherocylindrical values
 ********************************************************************/
export function convertSpheroCylinder({
    sphere = 0,
    cylinder = 0,
    axis = 0,
    sign = ''
}) {
    if (sign === '-') {
        if (cylinder > 0) {
            sphere += cylinder;
            cylinder = -cylinder;
            axis += 90;
        }
    } else if (sign === '+') {
        if (cylinder < 0) {
            sphere += cylinder;
            cylinder = Math.abs(cylinder);
            axis += 90;
        }
    }

    axis = checkAxis(axis);

    return { sphere, cylinder, axis };
}