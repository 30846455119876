import { Dropdown } from "primereact/dropdown";
import React from "react";
import { Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";

const MaterialSelect = ({ name, control, tabOrderIndex, options, hideLabel, inlineType }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 979 });
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const selectedValue = options.find((opt) => opt?.name === field.value?.name) ? field.value : options[0];

        return (
          <div className={`relative flex align-items-center ${isTabletOrMobile ? "w-full" : ""}`}>
            <Dropdown
              value={selectedValue}
              options={options}
              optionLabel="name"
              onChange={(e) => field.onChange(e.value)}
              className={`material-select ${isTabletOrMobile ? "flex-grow-1" : ""} ${inlineType ? "inline-select" : "border-round-md"} flex align-items-center text-sm`}
              tabIndex={tabOrderIndex}
            />
            {!hideLabel && <p className="absolute font-medium floated-label material-label">Material</p>}
          </div>
        );
      }}
    />
  );
};

export default MaterialSelect;
