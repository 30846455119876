import { checkAxis } from "../helpers/check.js";
import { deg2rad } from "../helpers/convert.js";

/********************************************************************
 * Calculate sections based on sphere, cylinder, and axis
 ********************************************************************/
export function calculateSections({ sphere = 0, cylinder = 0, axis = 0 }) {
    const section1 = sphere;
    const section2 = sphere + cylinder;

    return {
        section1,
        axis1: checkAxis(axis),
        section2,
        axis2: checkAxis(axis + 90),
    };
}

/********************************************************************
 * Calculate sphere, cylinder, and axis from sections
 ********************************************************************/
export function calculateSpheroCylinder({
    section1 = 0,
    section2 = 0,
    axis1 = 0,
    axis2 = 0,
}) {
    const sphere = section1;
    const cylinder = section2 - section1;

    return {
        sphere,
        cylinder,
        axis: checkAxis(axis1),
    };
}

/********************************************************************
 * Calculate the spherical equivalent of a sphero-cylinder combination
 ********************************************************************/
export function calculateSphericalEquivalent({
    sphere = 0,
    cylinder = 0,
    axis = 0,
}) {
    return sphere + cylinder / 2;
}

/********************************************************************
 * Calculate oblique axis
 ********************************************************************/
export function calculateObliqueAxis({
    sphere = 0,
    cylinder,
    axis,
    axisNew,
}) {
    const axisOblique = (axisNew - axis + 180) % 180;
    const cylinderNew = cylinder * Math.sin(deg2rad(axisOblique)) ** 2;

    return {
        sphere,
        cylinder: cylinderNew,
        axis: checkAxis(axisNew),
    };
}