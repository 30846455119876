import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ResultTable from "../ResultTable";
import ResultsModal from "../ResultsModal";
import CardContainer from "../CardContainer";
import InputsContainer from "../InputsContainer";
import InputsComponent from "../InputsComponent";
import BtnGroup from "../BtnGroup";
import { useForm } from "react-hook-form";
import {
  combineBaseNewInput,
  combineBaseOldInput,
  combinePrismNewInput,
  combinePrismOldInput,
} from "../../constants/constants";
import { useMediaQuery } from "react-responsive";
import ResultItem from "../ResultItem";
import { parseFormData } from "../../helpers/vertexHelpers";
import { prism } from "../../package";
import { AppContext } from "../../App";
import { notify } from "../../helpers/toast";
import { useTranslation } from "react-i18next";

const CombinePrismCalculator = () => {
  const [isCleared, setIsCleared] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const [transition, setTransition] = useState("");
  const [resultOD, setResultOD] = useState([]);
  const [resultOS, setResultOS] = useState([]);
  const [visibleResults, setVisibleResults] = useState(false);
  const [isResultODSkipped, setIsResultODSkipped] = useState(false);
  const [isResultOSSkipped, setIsResultOSSkipped] = useState(false);
  const [resultTransition, setResultTransition] = useState("");
  const [previousFraction, setPreviousFraction] = useState(null);
  const [isFirstSubmit, setIsFirstSubmit] = useState(true);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 979 });
  const showMenuHavbar = useMediaQuery({ maxWidth: 1199 });
  const { fraction } = useContext(AppContext);
  const { t } = useTranslation();

  const selectItems = useMemo(
    () => [
      { name: `${t("Out")}`, val: "Out" },
      { name: `${t("In")}`, val: "In" },
      { name: `${t("Up")}`, val: "Up" },
      { name: `${t("Down")}`, val: "Down" },
    ],
    [t]
  );
  const directionMap = useMemo(
    () => ({
      Out: { OD: 180, OS: 0 },
      In: { OD: 0, OS: 180 },
      Up: { OD: 90, OS: 90 },
      Down: { OD: 270, OS: 270 },
    }),
    []
  );

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const selectDirectionNewOD = watch("selectDirectionNewOD");
  const selectDirectionNewOS = watch("selectDirectionNewOS");
  const selectDirectionOldOD = watch("selectDirectionOldOD");
  const selectDirectionOldOS = watch("selectDirectionOldOS");
  const baseNewOD = watch("baseNewOD");
  const baseNewOS = watch("baseNewOS");
  const baseOldOD = watch("baseOldOD");
  const baseOldOS = watch("baseOldOS");

  const handleCalculations = useCallback(
    (data) => {
      const fields = ["prismOld", "prismNew", "baseOld", "baseNew", "selectDirectionNew", "selectDirectionOld"];

      const { OD, OS } = parseFormData(data, fields);
      const resultOD = prism.combinePrism({
        prism1: OD.prismOld,
        base1: OD.baseOld,
        prism2: OD.prismNew,
        base2: OD.baseNew,
        eye: "OD",
        fraction: fraction.value,
      });
      const resultOS = prism.combinePrism({
        prism1: OS.prismOld,
        base1: OS.baseOld,
        prism2: OS.prismNew,
        base2: OS.baseNew,
        eye: "OS",
        fraction: fraction.value,
      });

      setResultOD([
        {
          eye: "OD",
          base: resultOD.formattedBase,
          prism: Math.round(Number(resultOD.formattedPrism)),
        },
      ]);

      setResultOS([
        {
          eye: "OS",
          base: resultOS.formattedBase,
          prism: Math.round(Number(resultOS.formattedPrism)),
        },
      ]);
    },
    [fraction]
  );

  const updateBaseFields = useCallback(
    (direction, baseFieldPrefix) => {
      if (direction) {
        const { OD, OS } = directionMap[direction.name] || {};
        if (OD !== undefined) setValue(`${baseFieldPrefix}OD`, OD);
        if (OS !== undefined) setValue(`${baseFieldPrefix}OS`, OS);
      }
    },
    [setValue, directionMap]
  );

  const updateSelectDirection = useCallback(
    (baseValue, fieldPrefix) => {
      if (baseValue !== undefined) {
        const direction = Object.keys(directionMap).find((key) => directionMap[key][fieldPrefix] === baseValue);
        if (direction) setValue(`${fieldPrefix}`, direction);
      }
    },
    [setValue, directionMap]
  );

  useEffect(() => {
    if (!isCalculated) return;

    const formData = getValues();
    handleCalculations(formData);
  }, [fraction.value, getValues, handleCalculations, isCalculated]);

  useEffect(() => {
    if (previousFraction && previousFraction !== fraction) {
      setResultTransition("");
      setTimeout(() => {
        setResultTransition("ripple");
      }, 200);
    }
    setPreviousFraction(fraction);
  }, [fraction, previousFraction]);

  useEffect(() => {
    if (isCleared) {
      setTransition("transition-out");
      setIsCleared(false);
    }
    setTimeout(() => {
      setTransition("hidden");
    }, 200);
  }, [isCleared]);

  useEffect(() => {
    updateBaseFields(selectDirectionNewOD, "baseNew");
    updateBaseFields(selectDirectionNewOS, "baseNew");
    updateBaseFields(selectDirectionOldOD, "baseOld");
    updateBaseFields(selectDirectionOldOS, "baseOld");
  }, [selectDirectionNewOD, selectDirectionNewOS, selectDirectionOldOD, selectDirectionOldOS, updateBaseFields]);

  useEffect(() => {
    updateSelectDirection(baseNewOD, "selectDirectionNewOD");
    updateSelectDirection(baseNewOS, "selectDirectionNewOS");
    updateSelectDirection(baseOldOD, "selectDirectionOldOD");
    updateSelectDirection(baseOldOS, "selectDirectionOldOS");
  }, [baseNewOD, baseNewOS, baseOldOD, baseOldOS, updateSelectDirection]);

  const onSubmit = (data) => {
    if (!data.prismOldOD && !data.prismNewOD && !data.prismOldOS && !data.prismNewOS) {
      notify(t("toastInfo"));
      return;
    }
    if (!data.prismOldOD && !data.prismNewOD) {
      setIsResultODSkipped(true);
    } else setIsResultODSkipped(false);
    if (!data.prismOldOS && !data.prismNewOS) {
      setIsResultOSSkipped(true);
    } else setIsResultOSSkipped(false);

    if (isFirstSubmit) {
      setTransition("hidden");
      setTransition("transition");
      setIsFirstSubmit(false);
    } else {
      setTransition("");
      setResultTransition("");
      setTimeout(() => {
        setResultTransition("ripple");
      }, 200);
    }

    handleCalculations(data);
    setIsCalculated(true);
    setVisibleResults(true);
  };

  const createResultItems = (valueSet, isFirst) => {
    if (valueSet.prism === 0) {
      return null;
    }
    return (
      <>
        <ResultItem
          resultTransition={resultTransition}
          value={valueSet.prism}
          label="Prism"
          endAdornment="cm/m"
          isFirst={isFirst}
          resultItemWidth="res-prism"
        />
        <ResultItem
          resultTransition={resultTransition}
          value={valueSet.base}
          label="Base"
          endAdornment="°"
          isFirst={isFirst}
          resultItemWidth="res-prism"
        />
      </>
    );
  };

  return (
    <div className="w-max flex flex-column gap-5">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-column align-items-start justify-content-center gap-5"
      >
        <div className={`cards-container flex gap-2  ${showMenuHavbar ? "flex-wrap" : ""}`}>
          <CardContainer eye={t("OD")} isOpen={true} isCombinePrism={true} disableToggle={true} onToggle={() => {}}>
            <InputsContainer>
              <InputsComponent
                inputs={combinePrismOldInput}
                eye="OD"
                control={control}
                errors={errors}
                className="prism"
                tabOrderIndex={["1"]}
                inputMode="decimal"
                combinePrismPart={1}
              />
              <InputsComponent
                inputs={combineBaseOldInput}
                eye="OD"
                control={control}
                errors={errors}
                className="prism"
                tabOrderIndex={["2"]}
                inputMode="numeric"
                combinePrismPart={1}
                select={selectItems}
                selectName="selectDirectionOldOD"
              />
            </InputsContainer>
            <InputsContainer>
              <InputsComponent
                inputs={combinePrismNewInput}
                eye="OD"
                control={control}
                errors={errors}
                className="prism"
                tabOrderIndex={["3"]}
                inputMode="decimal"
                combinePrismPart={2}
              />
              <InputsComponent
                inputs={combineBaseNewInput}
                eye="OD"
                control={control}
                errors={errors}
                className="prism"
                tabOrderIndex={["4"]}
                inputMode="numeric"
                combinePrismPart={2}
                select={selectItems}
                selectName="selectDirectionNewOD"
              />
            </InputsContainer>
          </CardContainer>
          <CardContainer eye={t("OS")} isCombinePrism={true} isOpen={true} disableToggle={true} onToggle={() => {}}>
            <InputsContainer>
              <InputsComponent
                inputs={combinePrismOldInput}
                eye="OS"
                control={control}
                errors={errors}
                className="prism"
                tabOrderIndex={["5"]}
                inputMode="decimal"
                combinePrismPart={1}
              />
              <InputsComponent
                inputs={combineBaseOldInput}
                eye="OS"
                control={control}
                errors={errors}
                className="prism"
                tabOrderIndex={["6"]}
                inputMode="numeric"
                combinePrismPart={1}
                select={selectItems}
                selectName="selectDirectionOldOS"
              />
            </InputsContainer>
            <InputsContainer>
              <InputsComponent
                inputs={combinePrismNewInput}
                eye="OS"
                control={control}
                errors={errors}
                className="prism"
                tabOrderIndex={["7"]}
                inputMode="decimal"
                combinePrismPart={2}
              />
              <InputsComponent
                inputs={combineBaseNewInput}
                eye="OS"
                control={control}
                errors={errors}
                className="prism"
                tabOrderIndex={["8"]}
                inputMode="numeric"
                combinePrismPart={2}
                select={selectItems}
                selectName="selectDirectionNewOS"
              />
            </InputsContainer>
          </CardContainer>
        </div>
        <BtnGroup reset={reset} setIsCleared={setIsCleared} hideSetupBtns={showMenuHavbar} />
      </form>
      {isCalculated && !isTabletOrMobile && (
        <div className={`res-table gap-2 align-items-end ${transition}`}>
          <ResultTable
            classNames="flex-grow-1"
            values={resultOD}
            createResultItems={createResultItems}
            isSkipped={isResultODSkipped}
          ></ResultTable>

          <ResultTable
            classNames="flex-grow-1"
            values={resultOS}
            createResultItems={createResultItems}
            isSkipped={isResultOSSkipped}
          ></ResultTable>
        </div>
      )}
      {isTabletOrMobile && (
        <ResultsModal
          visible={visibleResults}
          setVisible={setVisibleResults}
          table1={<ResultTable values={resultOD} createResultItems={createResultItems} isSkipped={isResultODSkipped} />}
          table2={<ResultTable values={resultOS} createResultItems={createResultItems} isSkipped={isResultOSSkipped} />}
        ></ResultsModal>
      )}
    </div>
  );
};

export default CombinePrismCalculator;
