import { calculateSections } from "../functions/diopter.js";
import { checkAxis } from "./check.js";
import { defaultFraction } from "./constant.js";

/********************************************************************
 * Round sphero-cylinder parameters
 ********************************************************************/
export function roundSpheroCylinder({
    sphere = 0,
    cylinder = 0,
    axis = 0,
    fraction = defaultFraction,
}) {
    const sections      = calculateSections({ sphere, cylinder });
    const newCylinder   = sections.section2 - sections.section1;

    const roundSphere   = roundValue(sections.section1, fraction);
    const roundCylinder = roundValue(newCylinder, fraction);

    // Calculate spherocylindrical deviations for different rounding cases
    const cases = [
        roundSphere.deviancePositive + roundCylinder.deviancePositive,
        roundSphere.deviancePositive + roundCylinder.devianceNegative,
        roundSphere.devianceNegative + roundCylinder.deviancePositive,
        roundSphere.devianceNegative + roundCylinder.devianceNegative
    ];

    // Find the case with the lowest deviation
    const minCaseIndex = cases.indexOf(Math.min(...cases));

    // Assign sphere and cylinder values based on the best rounding case
    const sphereFinal   = (minCaseIndex < 2) ? roundSphere.valuePositive : roundSphere.valueNegative;
    const cylinderFinal = (minCaseIndex % 2 === 0) ? roundCylinder.valuePositive : roundCylinder.valueNegative;

    // Return the final rounded values
    return {
        sphere: sphereFinal,
        cylinder: cylinderFinal,
        axis: checkAxis(axis),
    };
}


/********************************************************************
 * Function to round a value according to the specified fraction
 ********************************************************************/
export function roundValue({
    value,
    fraction = defaultFraction
  }) {
    
    //console.log("roundValue received:", value);
  
    // Calculate the rounded-up and rounded-down values
    const valuePositive = Math.ceil(value * fraction) / fraction;
    const valueNegative = Math.floor(value * fraction) / fraction;
  
    // Calculate deviations from the original value
    const deviancePositive = valuePositive - value;
    const devianceNegative = valueNegative - value;
  
    // Select the value with the smallest deviation
    const rounding = Math.abs(deviancePositive) <= Math.abs(devianceNegative)
      ? valuePositive
      : valueNegative;
  
    //console.log("Rounded value:", rounding);
  
    return {
      rounding,           // Final rounded value with least deviation
      valuePositive,      // Rounded-up value
      valueNegative,      // Rounded-down value
      deviancePositive,   // Deviation for the rounded-up value
      devianceNegative    // Deviation for the rounded-down value
    };
  }