import { Button } from "primereact/button";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ReactComponent as CalculatorIcon } from "../assets/calculator.svg";
import { useTranslation } from "react-i18next";
import {
  HIGH_FRACTION,
  MEDIUM_FRACTION,
  SMALL_FRACTION,
} from "../constants/constants";
import { AppContext } from "../App";
import { Ripple } from "primereact/ripple";
import TipModal from "./TipModal";
import { useLocation } from "react-router-dom";
import Select from "./Select";

const BtnGroup = ({
  reset,
  setIsCleared,
  setIsChangeManufacturerClicked,
  setChangeManufacturerCount,
  setValue,
  hideCalculationBtns,
  hideSetupBtns,
}) => {
  const [visible, setVisible] = useState(false);
  const [maxTabIndex, setMaxTabIndex] = useState(null);
  const [isRGP, setIsRGP] = useState(false);

  const {
    fraction,
    setFraction,
    cylinderNotation,
    setCylinderNotation,
    manufacturer: chosenManufacturer,
    setManufacturer,

    filteredManufacturersList,
  } = useContext(AppContext);

  const { t } = useTranslation();
  const location = useLocation();

  const FRACTION_CYCLE = {
    [HIGH_FRACTION.value]: SMALL_FRACTION,
    [SMALL_FRACTION.value]: MEDIUM_FRACTION,
    [MEDIUM_FRACTION.value]: HIGH_FRACTION,
  };

  const storeFraction = (fraction) => {
    try {
      const fractionString = JSON.stringify(fraction);
      localStorage.setItem("fraction", fractionString);
    } catch (error) {
      console.error(
        "Failed to stringify fraction or store it in localStorage:",
        error
      );
    }
  };

  useEffect(() => {
    if (location.pathname === "/rgp") {
      setIsRGP(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    const elements = document.querySelectorAll("[tabindex]");
    const tabIndexes = Array.from(elements).map((el) =>
      parseInt(el.getAttribute("tabindex"), 10)
    );
    const btnTabIndex = Math.max(...tabIndexes) + 1;
    setMaxTabIndex(btnTabIndex);
  }, []);

  const handleManufacturerChange = useCallback(
    (e) => {
      setChangeManufacturerCount((prev) => prev + 1);
      setIsChangeManufacturerClicked(true);
      localStorage.setItem("manufacturer", e.value);
      setManufacturer(e.value);
      setValue("materialOldOD", undefined);
      setValue("materialOldOS", undefined);
    },
    [
      setChangeManufacturerCount,
      setIsChangeManufacturerClicked,
      setManufacturer,
      setValue,
    ]
  );
  return (
    <div className={`flex ${hideCalculationBtns ? "justify-content-end" : "justify-content-between"}  w-full`}>
      {!hideCalculationBtns && (
        <div className="calculation-btns flex gap-2 w-full">
          <Button className="dark-button text-sm flex align-items-center justify-content-center gap-2 p-ripple calc-btn" tabIndex={maxTabIndex?.toString()}>
            {t("Calculate")}
            <CalculatorIcon />
            <Ripple />
          </Button>
          <Button
            type="button"
            className="button-clear flex align-items-center justify-content-center  text-sm reset-btn px-4"
            outlined
            onClick={() => {
              setIsCleared(true);
              reset();
            }}
          >
            {t("Clear")}
          </Button>
        </div>
      )}
      {!hideSetupBtns && (
        <div className="btn-group flex gap-2">
          {isRGP && filteredManufacturersList?.length !== 0 && (
            <Select
              options={filteredManufacturersList.map((manufacturer) => manufacturer.manufacturer)}
              value={
                filteredManufacturersList.find((manufacturer) => manufacturer.manufacturer === chosenManufacturer)
                  ? chosenManufacturer
                  : filteredManufacturersList[0]?.manufacturer
              }
              onChange={handleManufacturerChange}
              className="manufacturer-select no-focus"
            />
          )}
          <button
            type="button"
            className="header-btn input button-clear flex align-items-center justify-content-center border-solid border-round-lg cursor-pointer"
            style={{ minWidth: "87px" }}
            onClick={() => {
              const nextFraction = FRACTION_CYCLE[fraction.value];
              if (nextFraction) {
                setFraction(nextFraction);
                storeFraction(nextFraction);
              }
            }}
          >
            <p className="text-sm">{fraction.formLabel}</p>
          </button>
          <button
            type="button"
            className="header-btn input button-clear flex align-items-center justify-content-center border-solid border-round-lg cursor-pointer"
            style={{ minWidth: "55px" }}
            onClick={() => {
              cylinderNotation === "Minus" ? setCylinderNotation("Plus") : setCylinderNotation("Minus");
            }}
          >
            <p className="text-sm">
              {cylinderNotation === "Minus" ? "-" : "+"}
              {t("Cyl")}
            </p>
          </button>
          <div
            className="header-btn input button-clear flex align-items-center justify-content-center border-solid border-round-lg cursor-pointer"
            style={{ width: "42px" }}
            onClick={() => setVisible(true)}
          >
            <i className="fal fa-question-circle fa-lg" style={{ color: "#696D77" }}></i>
          </div>
        </div>
      )}

      <TipModal visible={visible} setVisible={setVisible}></TipModal>
    </div>
  );
};

export default BtnGroup;
