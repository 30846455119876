import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import InputsContainer from "../InputsContainer";
import CardContainer from "../CardContainer";
import { yupResolver } from "@hookform/resolvers/yup";
import { parseFormData } from "../../helpers/vertexHelpers";
import { schema } from "../../helpers/vertexSchema";
import {
  vertexInputs,
  DEFAULT_VERTEX,
  sphereCylinderInputs,
  axisInput,
} from "../../constants/vertexConstants";
import { vertex } from "../../package";
import { AppContext } from "../../App";
import ResultItem from "../ResultItem";
import BtnGroup from "../BtnGroup";
import { useTranslation } from "react-i18next";
import ResultTable from "../ResultTable";
import { roundNumberFromString } from "../../helpers/formatHelpers";
import InputsComponent from "../InputsComponent";
import RefractionInputs from "../inputs/RefractionInputs";
import { useMediaQuery } from "react-responsive";
import ResultsModal from "../ResultsModal";
import { notify } from "../../helpers/toast";

const VertexCalculator = () => {
  const [isCalculated, setIsCalculated] = useState(false);
  const [isCleared, setIsCleared] = useState(false);
  const [result, setResult] = useState([]);
  const [transition, setTransition] = useState("");
  const [resultTransition, setResultTransition] = useState("");
  const [previousFraction, setPreviousFraction] = useState(null);
  const [previousSign, setPreviousSign] = useState(null);
  const [isFirstSubmit, setIsFirstSubmit] = useState(true);
  const [isResultSkipped, setIsResultSkipped] = useState(false);
  const [visibleResults, setVisibleResults] = useState(false);
  const [openPanel, setOpenPanel] = useState("Lens power");
  const [inputCardWidth1, setInputCardWidth1] = useState(0);

  const inputCardRef1 = useRef(null);

  const { fraction, cylinderNotation } = useContext(AppContext);
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 979 });
  const showMenuHavbar = useMediaQuery({ maxWidth: 1199 });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      vertexOldOD: DEFAULT_VERTEX.toFixed(1),
      vertexOldOS: DEFAULT_VERTEX.toFixed(1),
    },
  });

  const sphereODValue = watch("sphereOD");
  const cylinderODValue = watch("cylinderOD");
  const axisODValue = watch("axisOD");
  const sphereOSValue = watch("sphereOS");
  const cylinderOSValue = watch("cylinderOS");
  const axisOSValue = watch("axisOS");
  const vertexNewODValue = watch("vertexNewOD");
  const vertexNewOSValue = watch("vertexNewOS");

  const togglePanel = (title) => {
    setOpenPanel((prev) => (prev === title ? null : title));
  };

  const updateCardWidths = () => {
    if (inputCardRef1.current) {
      setInputCardWidth1(inputCardRef1.current.clientWidth);
    }
  };

  useEffect(() => {
    updateCardWidths();
    window.addEventListener("resize", updateCardWidths);
    return () => {
      window.removeEventListener("resize", updateCardWidths);
    };
  }, []);

  const handleCalculations = useCallback(
    (data) => {
      const fields = ["sphere", "cylinder", "axis", "vertexOld", "vertexNew"];

      const { OD, OS } = parseFormData(data, fields);
      const resultOD = vertex.calculateVertex({
        sphere: OD.sphere,
        cylinder: OD.cylinder,
        axis: OD.axis,
        vertexOld: OD.vertexOld,
        vertexNew: OD.vertexNew,
        fraction: fraction.value,
        sign: cylinderNotation === "Minus" ? "-" : "+",
      });
      const resultOS = vertex.calculateVertex({
        sphere: OS.sphere,
        cylinder: OS.cylinder,
        axis: OS.axis,
        vertexOld: OS.vertexOld,
        vertexNew: OS.vertexNew,
        fraction: fraction.value,
        sign: cylinderNotation === "Minus" ? "-" : "+",
      });

      setResult([
        {
          eye: "OD",
          sphere: roundNumberFromString(resultOD.formattedSphere),
          cylinder: roundNumberFromString(resultOD.formattedCylinder),
          axis: resultOD.formattedAxis,
        },
        {
          eye: "OS",
          sphere: roundNumberFromString(resultOS.formattedSphere),
          cylinder: roundNumberFromString(resultOS.formattedCylinder),
          axis: resultOS.formattedAxis,
        },
      ]);

      if (
        (roundNumberFromString(resultOS.formattedSphere) === "+0.00" &&
          roundNumberFromString(resultOS.formattedCylinder) === "+0.00") ||
        (roundNumberFromString(resultOD.formattedSphere) === "+0.00" &&
          roundNumberFromString(resultOD.formattedCylinder) === "+0.00")
      ) {
        setIsResultSkipped(true);
      } else {
        setIsResultSkipped(false);
      }
    },
    [cylinderNotation, fraction]
  );

  useEffect(() => {
    if (!isCalculated) return;

    const formData = getValues();
    handleCalculations(formData);
  }, [fraction.value, getValues, handleCalculations, isCalculated]);

  useEffect(() => {
    if (
      (previousFraction && previousFraction !== fraction) ||
      (previousSign && previousSign !== cylinderNotation)
    ) {
      setResultTransition("");
      setTimeout(() => {
        setResultTransition("ripple");
      }, 200);
    }
    setPreviousFraction(fraction);
    setPreviousSign(cylinderNotation);
  }, [cylinderNotation, fraction, previousFraction, previousSign]);

  useEffect(() => {
    if (isCleared) {
      setTransition("transition-out");
      setIsCleared(false);
    }
    setTimeout(() => {
      setTransition("hidden");
    }, 200);
  }, [isCleared]);

  const onSubmit = (data) => {
    if (
      !sphereODValue &&
      !cylinderODValue &&
      !axisODValue &&
      !sphereOSValue &&
      !cylinderOSValue &&
      !axisOSValue
    ) {
      notify(t("toastInfo"));
      return;
    }

    handleCalculations(data);
    setIsCalculated(true);
    setVisibleResults(true);

    if (
      !vertexNewODValue &&
      (sphereODValue || cylinderODValue || axisODValue)
    ) {
      setValue("vertexNewOD", "0.0");
    }

    if (
      !vertexNewOSValue &&
      (sphereOSValue || cylinderOSValue || axisOSValue)
    ) {
      setValue("vertexNewOS", "0.0");
    }

    if (!sphereODValue && cylinderODValue && axisODValue) {
      setValue("sphereOD", "+0.00");
    }

    if (!sphereOSValue && cylinderOSValue && axisOSValue) {
      setValue("sphereOS", "+0.00");
    }

    if (isFirstSubmit) {
      setTransition("hidden");
      setTransition("transition");
      setIsFirstSubmit(false);
    } else {
      setTransition("");
      setResultTransition("");
      setTimeout(() => {
        setResultTransition("ripple");
      }, 200);
    }
  };

  const createResultItems = (valueSet, isFirst) => {
    if (
      valueSet.sphere === "+0.00" &&
      valueSet.cylinder === "+0.00" &&
      valueSet.axis === 0
    ) {
      return null;
    }

    if (valueSet.cylinder === "+0.00") {
      valueSet.cylinder = "";
      valueSet.axis = "";
    }
    return (
      <>
        <ResultItem
          resultTransition={resultTransition}
          value={valueSet.sphere}
          label={t("Sphere")}
          endAdornment="dpt"
          isFirst={isFirst}
          isResultSkipped={isResultSkipped}
          resultItemWidth="res-sphere-cylinder"
        />
        <ResultItem
          resultTransition={resultTransition}
          value={valueSet.cylinder}
          label={t("Cylinder")}
          endAdornment="dpt"
          isFirst={isFirst}
          isResultSkipped={isResultSkipped}
          resultItemWidth="res-sphere-cylinder"
        />
        <ResultItem
          resultTransition={resultTransition}
          value={valueSet.axis}
          label={t("Axis")}
          endAdornment="°"
          isFirst={isFirst}
          isResultSkipped={isResultSkipped}
          resultItemWidth="res-axis"
        />
      </>
    );
  };

  return (
    <div className="general-container flex flex-column gap-5 w-max">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-column align-items-start justify-content-center gap-5  "
      >
        <div className="cards-container flex gap-2 flex-wrap">
          <div className="add-wrap" ref={inputCardRef1}>
            <RefractionInputs
              control={control}
              errors={errors}
              sphereCylinderODIndex={["1", "2"]}
              axisODIndex={["3"]}
              sphereCylinderOSIndex={["4", "5"]}
              axisOSIndex={["6"]}
              sphereCylinderInputs={sphereCylinderInputs}
              axisInput={axisInput}
              onToggle={togglePanel}
              isOpen={openPanel}
            ></RefractionInputs>
          </div>
          <CardContainer
            title={t("Vertex")}
            onToggle={togglePanel}
            isOpen={openPanel === t("Vertex")}
          >
            <InputsContainer description={isTabletOrMobile && t("OD")}>
              <InputsComponent
                inputs={vertexInputs}
                eye="OD"
                control={control}
                errors={errors}
                className="vertex-inp"
                tabOrderIndex={["7", "8"]}
                inputMode="decimal"
              ></InputsComponent>
            </InputsContainer>
            <InputsContainer description={isTabletOrMobile && t("OD")}>
              <InputsComponent
                inputs={vertexInputs}
                eye="OS"
                control={control}
                errors={errors}
                className="vertex-inp"
                tabOrderIndex={["9", "10"]}
                inputMode="decimal"
              ></InputsComponent>
            </InputsContainer>
          </CardContainer>
        </div>
        <BtnGroup
          hideSetupBtns={showMenuHavbar}
          reset={reset}
          setIsCleared={setIsCleared}
        />
      </form>
      {isCalculated && !isTabletOrMobile && (
        <div className={`${transition}`}>
          <ResultTable
            values={result}
            createResultItems={createResultItems}
            width={inputCardWidth1}
          ></ResultTable>
        </div>
      )}
      {isTabletOrMobile && (
        <ResultsModal
          visible={visibleResults}
          setVisible={setVisibleResults}
          table1={
            <ResultTable
              values={result}
              createResultItems={createResultItems}
            />
          }
        ></ResultsModal>
      )}
    </div>
  );
};

export default VertexCalculator;
