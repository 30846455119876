import React from "react";
import { SelectButton } from "primereact/selectbutton";

const SelectButtons = ({ options, onChange, value }) => {
  return (
    <div className="flex justify-content-center w-max">
      <SelectButton value={value} onChange={onChange} options={options} />
    </div>
  );
};

export default SelectButtons;
