import React from "react";
import CardContainer from "../CardContainer";
import InputsContainer from "../InputsContainer";
import InputsComponent from "../InputsComponent";
import RotationLens from "../rgpCalculator/RotationLens";
import RotationModal from "../rgpCalculator/RotationModal";
import { rotationInput, stabilisation } from "../../constants/constants";
import { useTranslation } from "react-i18next";

const RotationInputs = ({
  control,
  errors,
  setValue,
  visible,
  setVisible,
  handleRotationClick,
  lensOrientation,
  rotationOD,
  rotationOS,
  rotationODIndex,
  rotationOSIndex,
  onToggle,
  isOpen,
}) => {
  const { t } = useTranslation();

  return (
    <CardContainer
      title={
        lensOrientation === stabilisation ? t("Stabilisation") : t("Rotation")
      }
      onToggle={onToggle}
      isOpen={
        isOpen ===
        (lensOrientation === stabilisation ? t("Stabilisation") : t("Rotation"))
      }
    >
      <InputsContainer>
        <InputsComponent
          inputs={rotationInput}
          eye="OD"
          control={control}
          errors={errors}
          className="max-w-3rem"
          tabOrderIndex={rotationODIndex}
          orientation={lensOrientation}
        ></InputsComponent>
        <RotationLens rotate={rotationOD} onClick={handleRotationClick} />
        <RotationModal
          setValue={setValue}
          visible={visible}
          setVisible={setVisible}
          rotateOD={rotationOD}
          rotateOS={rotationOS}
        ></RotationModal>
      </InputsContainer>
      <InputsContainer>
        <InputsComponent
          inputs={rotationInput}
          eye="OS"
          control={control}
          errors={errors}
          className="max-w-3rem"
          tabOrderIndex={rotationOSIndex}
          orientation={lensOrientation}
        ></InputsComponent>
        <RotationLens rotate={rotationOS} onClick={handleRotationClick} />
      </InputsContainer>
    </CardContainer>
  );
};

export default RotationInputs;
