const getCorrespondingNewFieldName = (inputName) => {
  return inputName.replace(/Old/, "New");
};

const formatSphereCylinderValue = (value) => {
  if (value === undefined || value === null || value.trim() === "") return;
  const num = parseFloat(value);
  if (isNaN(num)) return "+0.00";
  return num >= 0 ? `+${num.toFixed(2)} ` : `${num.toFixed(2)} `;
};

const formatVertexValue = (value) => {
  if (value === undefined || value === null || value.trim() === "") return;
  return `${parseFloat(value).toFixed(1)} `;
};

const formatPrismValue = (value) => {
  if (value === undefined || value === null || value.trim() === "") return;
  return `${parseFloat(value).toFixed(2)} `;
};

const formatRadiusValue = (
  name,
  value,
  getValues,
  setValue,
  isRadiusNew1ODChanged,
  isRadiusNew2ODChanged,
  isRadiusNew1OSChanged,
  isRadiusNew2OSChanged
) => {
  if (value === undefined || value === null || value.trim() === "") return;
  const num = parseFloat(value);
  if (isNaN(num)) return "0.00";

  if (name.toLowerCase().includes("old")) {
    const correspondingFieldName = getCorrespondingNewFieldName(name);

    const fieldChangedFlags = {
      radiusNew1OD: isRadiusNew1ODChanged,
      radiusNew1OS: isRadiusNew1OSChanged,
      radiusNew2OD: isRadiusNew2ODChanged,
      radiusNew2OS: isRadiusNew2OSChanged,
    };

    const shouldSetValue =
      !getValues(correspondingFieldName) ||
      !fieldChangedFlags[correspondingFieldName];

    if (shouldSetValue) {
      setValue(correspondingFieldName, num.toFixed(2));
    }
  }

  return num.toFixed(2);
};

const formatSphereCylinderInput = (value) => {
  // Replace commas with dots
  let updatedValue = value.replace(/,/g, ".");

  // Allow only numeric values, dots, commas, and leading + or - signs
  updatedValue = updatedValue.replace(/[^0-9.,+-]/g, "");

  // Ensure only one dot or comma is present
  const parts = updatedValue.split(/[.,]/);

  const [integerPart, fractionalPart] = parts;

  if (parts.length > 2) {
    updatedValue = `${integerPart}.${parts.slice(1).join("").replace(/\./g, "")}`;
  }

  // Ensure only one leading + or - is present
  updatedValue = updatedValue.replace(/^[+-]+/, (match) => match[0]);

  // Ensure no additional + or - signs are present after the initial sign
  updatedValue = updatedValue.replace(/([+-].*)[+-]/g, "$1");

  //Allow only 2 digits before and after decimal point.
  const formattedIntegerPart =
    parseFloat(integerPart).length > 2 ? integerPart.slice(0, 2) : integerPart;

  const formattedFractionalPart = fractionalPart
    ? fractionalPart.slice(0, 2)
    : "";

  if (formattedFractionalPart) {
    if (value.includes("."))
      updatedValue = formattedIntegerPart + `.${formattedFractionalPart}`;
  }

  if (parseFloat(integerPart) > 99 || parseFloat(integerPart) < -99) {
    updatedValue = integerPart.slice(0, 2);
  }

  return updatedValue;
};

const formatVertexInput = (value) => {
  // Replace commas with dots
  let updatedValue = value.replace(/,/g, ".");

  // Remove all non-numeric characters except for dots
  updatedValue = updatedValue.replace(/[^0-9.,]/g, "");

  // Ensure only one dot or comma is present
  const parts = updatedValue.split(/[.,]/);
  const [integerPart, fractionalPart] = parts;

  if (parts.length > 2) {
    updatedValue = `${integerPart}.${parts.slice(1).join("").replace(/\./g, "")}`;
  }
  //Allow only 2 digits before and 1 digit after decimal point.
  const formattedIntegerPart =
    parseFloat(integerPart).length > 2 ? integerPart.slice(0, 2) : integerPart;

  const formattedFractionalPart = fractionalPart
    ? fractionalPart.slice(0, 1)
    : "";

  if (formattedFractionalPart) {
    if (value.includes("."))
      updatedValue = formattedIntegerPart + `.${formattedFractionalPart}`;
  }

  if (integerPart.length > 2) {
    updatedValue = integerPart.slice(0, 2);
  }

  return updatedValue;
};

export const formatAxisInput = (value) => {
  // Remove any characters that are not digits
  let updatedValue = value.replace(/\D/g, "");

  // Remove leading zeros if any, unless the value is "0"
  updatedValue = updatedValue.replace(/^0+(?!$)/, "");

  if (value.length > 3) updatedValue = updatedValue.slice(0, 3);

  // Return the updated value, which will be an empty string if no digits were present
  return updatedValue;
};

export const formatRotatingLensInput = (value) => {
  // Remove any characters that are not digits or a leading minus sign
  let updatedValue = value.replace(/(?!^-)[^\d]/g, "");

  // Ensure there is only one minus sign at the start if it exists
  if (updatedValue.startsWith("-")) {
    updatedValue = "-" + updatedValue.slice(1).replace(/-/g, "");
  }

  // Remove leading zeros if any, unless the value is "0" or "-0"
  updatedValue = updatedValue.replace(/^(-?)0+(?!$)/, "$1");

  // Limit the length to 3 characters if applicable
  if (updatedValue.length > 3) updatedValue = updatedValue.slice(0, 3);

  return updatedValue;
};

const formatRadiusInput = (
  value,
  name,
  setIsRadiusNew1ODChanged,
  setIsRadiusNew2ODChanged,
  setIsRadiusNew1OSChanged,
  setIsRadiusNew2OSChanged
) => {
  // Replace commas with dots
  let updatedValue = value.replace(/,/g, ".");

  // Remove all non-numeric characters except for dots
  updatedValue = updatedValue.replace(/[^0-9.,]/g, "");

  // Ensure only one dot or comma is present
  const parts = updatedValue.split(/[.,]/);
  const [integerPart, fractionalPart] = parts;

  if (parts.length > 2) {
    updatedValue = `${integerPart}.${parts.slice(1).join("").replace(/\./g, "")}`;
  }

  //Allow only 2 digits before and after decimal point.
  const formattedIntegerPart =
    parseFloat(integerPart).length > 2 ? integerPart.slice(0, 2) : integerPart;

  const formattedFractionalPart = fractionalPart
    ? fractionalPart.slice(0, 2)
    : "";

  if (formattedFractionalPart) {
    if (value.includes("."))
      updatedValue = formattedIntegerPart + `.${formattedFractionalPart}`;
  }

  if (parseFloat(integerPart) > 99 || parseFloat(integerPart) < -99) {
    updatedValue = integerPart.slice(0, 2);
  }

  if (name === "radiusNew1OD") {
    setIsRadiusNew1ODChanged(true);
  }
  if (name === "radiusNew2OD") {
    setIsRadiusNew2ODChanged(true);
  }
  if (name === "radiusNew1OS") {
    setIsRadiusNew1OSChanged(true);
  }
  if (name === "radiusNew2OS") {
    setIsRadiusNew2OSChanged(true);
  }

  return updatedValue;
};

export const getFormattedValue = (
  name,
  value,
  isBlur,
  getValues,
  setValue,
  orientation,
  isRadiusNew1ODChanged,
  isRadiusNew2ODChanged,
  isRadiusNew1OSChanged,
  isRadiusNew2OSChanged,
  setIsRadiusNew1ODChanged,
  setIsRadiusNew2ODChanged,
  setIsRadiusNew1OSChanged,
  setIsRadiusNew2OSChanged
) => {
  if (
    name.toLowerCase().includes("sphere") ||
    name.toLowerCase().includes("cylinder") ||
    name.toLowerCase().includes("section")
  ) {
    return isBlur
      ? formatSphereCylinderValue(value)
      : formatSphereCylinderInput(value);
  }
  if (
    name.toLowerCase().includes("axis") ||
    name.toLowerCase().includes("base")
  ) {
    return formatAxisInput(value);
  }

  if (name.toLowerCase().includes("rotation")) {
    return formatRotatingLensInput(value);
  }

  if (name.toLowerCase().includes("vertex")) {
    return isBlur ? formatVertexValue(value) : formatVertexInput(value);
  }
  if (name.toLowerCase().includes("prism")) {
    return isBlur ? formatPrismValue(value) : formatRadiusInput(value);
  }
  if (name.toLowerCase().includes("radius")) {
    return isBlur
      ? formatRadiusValue(
          name,
          value,
          getValues,
          setValue,
          isRadiusNew1ODChanged,
          isRadiusNew2ODChanged,
          isRadiusNew1OSChanged,
          isRadiusNew2OSChanged
        )
      : formatRadiusInput(
          value,
          name,
          setIsRadiusNew1ODChanged,
          setIsRadiusNew2ODChanged,
          setIsRadiusNew1OSChanged,
          setIsRadiusNew2OSChanged
        );
  }

  return value;
};
