import React from "react";
import { vertexInput } from "../../constants/constants";
import CardContainer from "../CardContainer";
import InputsContainer from "../InputsContainer";
import InputsComponent from "../InputsComponent";
import { useTranslation } from "react-i18next";

const VertexInputs = ({
  control,
  errors,
  vertexODIndex,
  vertexOSIndex,
  onToggle,
  isOpen,
}) => {
  const { t } = useTranslation();
  return (
    <CardContainer
      title={t("Vertex")}
      onToggle={onToggle}
      isOpen={isOpen === t("Vertex")}
    >
      <InputsContainer style={{ window: "100%" }}>
        <InputsComponent
          inputs={vertexInput}
          eye="OD"
          control={control}
          errors={errors}
          className="max-w-5rem"
          tabOrderIndex={vertexODIndex}
          inputMode="decimal"
        ></InputsComponent>
      </InputsContainer>
      <InputsContainer>
        <InputsComponent
          inputs={vertexInput}
          eye="OS"
          control={control}
          errors={errors}
          className="max-w-5rem"
          tabOrderIndex={vertexOSIndex}
          inputMode="decimal"
        ></InputsComponent>
      </InputsContainer>
    </CardContainer>
  );
};

export default VertexInputs;
