import React from "react";
import CardContainer from "../CardContainer";
import { useTranslation } from "react-i18next";
import InputsContainer from "../InputsContainer";
import InputsComponent from "../InputsComponent";
import RotationLens from "../rgpCalculator/RotationLens";
import RotationModal from "../rgpCalculator/RotationModal";
import {
  rotationInput,
  rotationInputSm,
  vertexInput,
  vertexInputSm,
} from "../../constants/constants";

const RotationAndVertexInputs = ({
  control,
  errors,
  lensOrientation,
  rotationOD,
  rotationOS,
  handleRotationClick,
  setValue,
  visible,
  setVisible,
  isTabletOrMobile,
  onToggle,
  isOpen,
}) => {
  const { t } = useTranslation();
  return (
    <CardContainer
      title={t("Rotation & Vertex")}
      onToggle={onToggle}
      isOpen={isOpen === t("Rotation & Vertex")}
    >
      <InputsContainer description={t("OD")}>
        <div className="rotation-vertex flex align-items-end container-gap">
          <InputsComponent
            inputs={isTabletOrMobile ? rotationInputSm : rotationInput}
            eye="OD"
            control={control}
            errors={errors}
            className="axis"
            tabOrderIndex={["7"]}
            orientation={lensOrientation}
          ></InputsComponent>
          <RotationLens rotate={rotationOD} onClick={handleRotationClick} />
        </div>

        <InputsComponent
          inputs={isTabletOrMobile ? vertexInputSm : vertexInput}
          eye="OD"
          control={control}
          errors={errors}
          className="vertex"
          tabOrderIndex={["21"]}
          inputMode="decimal"
        />
      </InputsContainer>
      <InputsContainer description={t("OS")}>
        <div className="rotation-vertex flex align-items-end container-gap">
          <InputsComponent
            inputs={isTabletOrMobile ? rotationInputSm : rotationInput}
            eye="OS"
            control={control}
            errors={errors}
            className="axis"
            tabOrderIndex={["14"]}
            orientation={lensOrientation}
          ></InputsComponent>
          <RotationLens rotate={rotationOS} onClick={handleRotationClick} />
        </div>
        <InputsComponent
          inputs={vertexInput}
          eye="OS"
          control={control}
          errors={errors}
          className="vertex"
          tabOrderIndex={["21"]}
          inputMode="decimal"
        />
      </InputsContainer>
      <RotationModal
        setValue={setValue}
        visible={visible}
        setVisible={setVisible}
        rotateOD={rotationOD}
        rotateOS={rotationOS}
      ></RotationModal>
    </CardContainer>
  );
};

export default RotationAndVertexInputs;
