import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { de } from "./locales/de.js";
import { en } from "./locales/en.js";

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    detection: {
      order: ["localStorage", "cookie", "sessionStorage", "navigator"],
      caches: ["localStorage", "cookie"],
    },
  });

export default i18n;
