import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import BtnGroup from "../BtnGroup";
import ResultTable from "../ResultTable";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DEFAULT_VERTEX } from "../../constants/vertexConstants";
import { schema } from "../../helpers/softLensSchema";
import { parseFormData } from "../../helpers/vertexHelpers";
import { softlens } from "../../package";
import { roundNumberFromString } from "../../helpers/formatHelpers";
import { AppContext } from "../../App";
import ResultItem from "../ResultItem";
import RefractionInputs from "../inputs/RefractionInputs";
import OverRefractionInputs from "../inputs/OverRefractionInputs";
import RotationInputs from "../inputs/RotationInputs";
import VertexInputs from "../inputs/VertexInputs";
import { lensPowerAxisInput, lensPowerSphereCylinderInputs } from "../../constants/constants";
import { useMediaQuery } from "react-responsive";
import RotationAndVertexInputs from "../inputs/RotationAndVertexInputs";
import ResultsModal from "../ResultsModal";
import { notify } from "../../helpers/toast";

const SoftLensCalculator = () => {
  const [visible, setVisible] = useState(false);
  const [visibleResults, setVisibleResults] = useState(false);
  const [isCleared, setIsCleared] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const [transition, setTransition] = useState("");
  const [resultTransition, setResultTransition] = useState("");
  const [previousFraction, setPreviousFraction] = useState(null);
  const [previousSign, setPreviousSign] = useState(null);
  const [isFirstSubmit, setIsFirstSubmit] = useState(true);
  const [isResultSkipped, setIsResultSkipped] = useState(false);
  const [result, setResult] = useState([]);
  const [openPanel, setOpenPanel] = useState("Lens power");
  const [inputCardWidth1, setInputCardWidth1] = useState(0);

  const inputCardRef1 = useRef(null);

  const { t } = useTranslation();
  const { fraction, cylinderNotation, lensOrientation } = useContext(AppContext);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 979 });
  const showMenuHavbar = useMediaQuery({ maxWidth: 1199 });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rotationOD: 0,
      rotationOS: 0,
      vertexOD: DEFAULT_VERTEX.toFixed(1),
      vertexOS: DEFAULT_VERTEX.toFixed(1),
    },
  });

  const rotationOD = watch("rotationOD");
  const rotationOS = watch("rotationOS");
  const sphereOldODValue = watch("lensPowerSphereOD");
  const sphereOldOSValue = watch("lensPowerSphereOS");
  const cylinderOldODValue = watch("lensPowerCylinderOD");
  const cylinderOldOSValue = watch("lensPowerCylinderOS");
  const axisOldODValue = watch("lensPowerAxisOD");
  const axisOldOSValue = watch("lensPowerAxisOS");

  const updateCardWidths = () => {
    if (inputCardRef1.current) {
      setInputCardWidth1(inputCardRef1.current.clientWidth);
    }
  };

  useEffect(() => {
    updateCardWidths();
    window.addEventListener("resize", updateCardWidths);
    return () => {
      window.removeEventListener("resize", updateCardWidths);
    };
  }, []);

  const togglePanel = (title) => {
    setOpenPanel((prev) => (prev === title ? null : title));
  };

  const handleCalculations = useCallback(
    (data) => {
      const fields = [
        "vertex",
        "rotation",
        "lensPowerSphere",
        "lensPowerCylinder",
        "lensPowerAxis",
        "overRefractionSphere",
        "overRefractionCylinder",
        "overRefractionAxis",
      ];
      const { OD, OS } = parseFormData(data, fields);
      const resultOD = softlens.calculateSoftLens({
        sphere1: OD.lensPowerSphere,
        cylinder1: OD.lensPowerCylinder,
        axis1: OD.lensPowerAxis,
        rotation: OD.rotation,
        sphere2: OD.overRefractionSphere,
        cylinder2: OD.overRefractionCylinder,
        axis2: OD.overRefractionAxis,
        vertex: OD.vertex,
        fraction: fraction.value,
        sign: cylinderNotation === "Minus" ? "-" : "+",
      });
      const resultOS = softlens.calculateSoftLens({
        sphere1: OS.lensPowerSphere,
        cylinder1: OS.lensPowerCylinder,
        axis1: OS.lensPowerAxis,
        rotation: OS.rotation,
        sphere2: OS.overRefractionSphere,
        cylinder2: OS.overRefractionCylinder,
        axis2: OS.overRefractionAxis,
        vertex: OS.vertex,
        fraction: fraction.value,
        sign: cylinderNotation === "Minus" ? "-" : "+",
      });

      setResult([
        {
          eye: "OD",
          sphere: roundNumberFromString(resultOD.formattedSphere),
          cylinder: roundNumberFromString(resultOD.formattedCylinder),
          axis: resultOD.formattedAxis,
        },
        {
          eye: "OS",
          sphere: roundNumberFromString(resultOS.formattedSphere),
          cylinder: roundNumberFromString(resultOS.formattedCylinder),
          axis: resultOS.formattedAxis,
        },
      ]);

      if (
        (roundNumberFromString(resultOS.formattedSphere) === "+0.00" && roundNumberFromString(resultOS.formattedCylinder) === "+0.00") ||
        (roundNumberFromString(resultOD.formattedSphere) === "+0.00" && roundNumberFromString(resultOD.formattedCylinder) === "+0.00")
      ) {
        setIsResultSkipped(true);
      } else {
        setIsResultSkipped(false);
      }
    },
    [cylinderNotation, fraction.value]
  );

  useEffect(() => {
    if ((previousFraction && previousFraction !== fraction) || (previousSign && previousSign !== cylinderNotation)) {
      setResultTransition("");
      setTimeout(() => {
        setResultTransition("ripple");
      }, 200);
    }
    setPreviousFraction(fraction);
    setPreviousSign(cylinderNotation);
    const formData = getValues();
    handleCalculations(formData);
  }, [cylinderNotation, fraction, getValues, handleCalculations, previousFraction, previousSign]);

  useEffect(() => {
    if (isCleared) {
      setTransition("transition-out");
      setIsCleared(false);
    }
    setTimeout(() => {
      setTransition("hidden");
    }, 200);
  }, [isCleared]);

  const handleRotationClick = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const onSubmit = (data) => {
    if (!sphereOldODValue && !sphereOldOSValue && !cylinderOldODValue && !cylinderOldOSValue && !axisOldODValue && !axisOldOSValue) {
      notify(t("toastInfo"));
      return;
    }

    handleCalculations(data);
    setIsCalculated(true);
    setVisibleResults(true);

    if (!sphereOldODValue && axisOldODValue && cylinderOldODValue) {
      setValue("lensPowerSphereOD", "+0.00");
    }
    if (!sphereOldOSValue && axisOldOSValue && cylinderOldOSValue) {
      setValue("lensPowerSphereOS", "+0.00");
    }

    if (isFirstSubmit) {
      setTransition("hidden");
      setTransition("transition");
      setIsFirstSubmit(false);
    } else {
      setTransition("");
      setResultTransition("");
      setTimeout(() => {
        setResultTransition("ripple");
      }, 200);
    }
  };

  const createResultItems = (valueSet, isFirst) => {
    if (valueSet.cylinder === "+0.00") {
      valueSet.cylinder = "";
      valueSet.axis = "";
    }

    if (valueSet.sphere === "+0.00" && valueSet.cylinder === "" && valueSet.axis === "") return null;

    return (
      <>
        <ResultItem
          resultTransition={resultTransition}
          value={!isNaN(valueSet.sphere) ? valueSet.sphere : ""}
          label={t("Sphere")}
          endAdornment="dpt"
          isFirst={isFirst}
          isResultSkipped={isResultSkipped}
          resultItemWidth="res-sphere-cylinder"
        />
        <ResultItem
          resultTransition={resultTransition}
          value={!isNaN(valueSet.cylinder) ? valueSet.cylinder : ""}
          label={t("Cylinder")}
          endAdornment="dpt"
          isFirst={isFirst}
          isResultSkipped={isResultSkipped}
          resultItemWidth="res-sphere-cylinder"
        />
        <ResultItem
          resultTransition={resultTransition}
          value={valueSet.axis}
          label={t("Axis")}
          endAdornment="°"
          isFirst={isFirst}
          isResultSkipped={isResultSkipped}
          resultItemWidth="res-axis"
        />
      </>
    );
  };

  return (
    <div className="w-max flex flex-column gap-5">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column  justify-content-center gap-5  ">
        <div className="cards-container flex flex-column  gap-2 ">
          <div className="cards-container flex gap-2  flex-wrap  ">
            <div className="add-wrap" ref={inputCardRef1}>
              <RefractionInputs
                control={control}
                errors={errors}
                sphereCylinderODIndex={["1", "2"]}
                axisODIndex={["3"]}
                sphereCylinderOSIndex={["5", "6"]}
                axisOSIndex={["7"]}
                sphereCylinderInputs={lensPowerSphereCylinderInputs}
                axisInput={lensPowerAxisInput}
                onToggle={togglePanel}
                isOpen={openPanel}
              ></RefractionInputs>
            </div>
            {!isTabletOrMobile ? (
              <RotationInputs
                control={control}
                errors={errors}
                setValue={setValue}
                visible={visible}
                setVisible={setVisible}
                handleRotationClick={handleRotationClick}
                lensOrientation={lensOrientation}
                rotationOD={rotationOD}
                rotationOS={rotationOS}
                rotationODIndex={["4"]}
                rotationOSIndex={["8"]}
              ></RotationInputs>
            ) : (
              <RotationAndVertexInputs
                isTabletOrMobile={isTabletOrMobile}
                control={control}
                errors={errors}
                lensOrientation={lensOrientation}
                rotationOD={rotationOD}
                rotationOS={rotationOS}
                handleRotationClick={handleRotationClick}
                setValue={setValue}
                visible={visible}
                setVisible={setVisible}
                onToggle={togglePanel}
                isOpen={openPanel}
              ></RotationAndVertexInputs>
            )}
          </div>
          <div className="cards-container flex gap-2  flex-wrap">
            <OverRefractionInputs
              control={control}
              errors={errors}
              sphereCylinderODIndex={["9", "10"]}
              axisODIndex={["11"]}
              sphereCylinderOSIndex={["13", "14"]}
              axisOSIndex={["15"]}
              onToggle={togglePanel}
              isOpen={openPanel}
            ></OverRefractionInputs>
            {!isTabletOrMobile && <VertexInputs control={control} errors={errors} vertexODIndex={["12"]} vertexOSIndex={["16"]}></VertexInputs>}
          </div>
        </div>
        <BtnGroup reset={reset} setIsCleared={setIsCleared} hideSetupBtns={showMenuHavbar}></BtnGroup>
      </form>
      {isCalculated && !isTabletOrMobile && (
        <div className="relative">
          <div className={`${transition}`}>
            <ResultTable values={result} createResultItems={createResultItems} width={inputCardWidth1}></ResultTable>
          </div>
        </div>
      )}
      {isTabletOrMobile && (
        <ResultsModal
          visible={visibleResults}
          setVisible={setVisibleResults}
          table1={<ResultTable values={result} createResultItems={createResultItems} />}
        ></ResultsModal>
      )}
    </div>
  );
};

export default SoftLensCalculator;
