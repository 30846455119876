import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

const ResultItem = ({
  value,
  label,
  endAdornment,
  isFirst,
  resultTransition,
  isResultSkipped,
  isMaterial,
  className,
  resultItemWidth,
}) => {
  const [applyResultTransition, setApplyResultTransition] = useState(false);
  const [displayedValue, setDisplayedValue] = useState(value);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 979 });

  useEffect(() => {
    if (value !== displayedValue) {
      setApplyResultTransition(true);
      const timeoutId = setTimeout(() => {
        setDisplayedValue(value);
        setApplyResultTransition(false);
      }, 700);

      return () => clearTimeout(timeoutId);
    }
  }, [value, displayedValue]);

  return (
    <div className={`res-item-container flex flex-column text-sm ${className}`}>
      {(isFirst || isResultSkipped) && (
        <p className=" result-item  font-medium bg-white px-1 border-round-lg">
          {label}
        </p>
      )}

      <div className="flex relative">
        <div
          className={`${
            applyResultTransition ? resultTransition : ""
          } ${!isTabletOrMobile ? resultItemWidth : "w-full"} input flex align-items-center result-item text-gray-600 border border-solid border-round-left-md  ${!isMaterial ? "border-noround-right border-right-none" : "border-round-right-md"} p-inputtext-sm`}
          style={{ height: "42px" }}
        >
          <p>{displayedValue}</p>
        </div>
        {endAdornment && (
          <div className="res-adornment result-item flex justify-content-center align-items-center  pointer-events-none  border-round-right-md border-left-none pl-0">
            {(displayedValue || displayedValue === 0) && <p>{endAdornment}</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export default ResultItem;
