import { defaultFraction } from "../package/helpers/constant";

export const SMALL_FRACTION = {
  formLabel: "±0.01 dpt",
  value: defaultFraction,
};
export const MEDIUM_FRACTION = {
  formLabel: "±0.12 dpt",
  value: 8,
};
export const HIGH_FRACTION = { formLabel: "±0.25 dpt", value: 4 };

export const fractions = [SMALL_FRACTION, MEDIUM_FRACTION, HIGH_FRACTION];
export const cylinderNotations = { Minus: "-", Plus: "+" };
export const curvatureUnits = ["mm", "dpt"];
export const prismUnits = ["cm/m", "pdpt"];

export const languages = {
  English: "en-US",
  German: "de",
};

export const stabilisation = "Stabilisation (TABO)";
export const rotation = "Lens rotation";

export const lensOrientations = [stabilisation, rotation];

export const parametersOldInputs = [
  { name: "radiusOld1", placeholder: "Radius 1", endAdornment: "mm" },
  { name: "radiusOld2", placeholder: "Radius 2", endAdornment: "mm" },
];

export const parametersNewInputs = [
  { name: "radiusNew1", placeholder: "Radius 1", endAdornment: "mm" },
  { name: "radiusNew2", placeholder: "Radius 2", endAdornment: "mm" },
];

export const lensPowerSphereCylinderInputs = [
  { name: "lensPowerSphere", placeholder: "Sphere", endAdornment: "dpt" },
  { name: "lensPowerCylinder", placeholder: "Cylinder", endAdornment: "dpt" },
];
export const lensPowerAxisInput = [
  { name: "lensPowerAxis", placeholder: "Axis", endAdornment: "°" },
];

export const RGPAirSectionOld1 = [
  { name: "sectionOld1", placeholder: "Section 1", endAdornment: "dpt" },
];
export const RGPAirSectionOld2 = [
  { name: "sectionOld2", placeholder: "Section 2", endAdornment: "dpt" },
];

export const overRefractionSphereCylinderInputs = [
  { name: "overRefractionSphere", placeholder: "Sphere", endAdornment: "dpt" },
  {
    name: "overRefractionCylinder",
    placeholder: "Cylinder",
    endAdornment: "dpt",
  },
];

export const overRefractionAxisInput = [
  { name: "overRefractionAxis", placeholder: "Axis", endAdornment: "°" },
];

export const rotationInput = [{ name: "rotation", endAdornment: "°" }];

export const vertexInput = [{ name: "vertex", endAdornment: "mm" }];

export const rotationInputSm = [
  { name: "rotation", placeholder: "Rotation", endAdornment: "°" },
];

export const vertexInputSm = [
  { name: "vertex", placeholder: "Vertex", endAdornment: "mm" },
];

export const combinePrismOldInput = [
  {
    name: "prismOld",
    placeholder: "Prism",
    endAdornment: "cm/m",
  },
];

export const combineBaseOldInput = [
  {
    name: "baseOld",
    placeholder: "Base",
    endAdornment: "°",
    select: "selectDirectionOld",
  },
];

export const combinePrismNewInput = [
  {
    name: "prismNew",
    placeholder: "Prism",
    endAdornment: "cm/m",
  },
];

export const combineBaseNewInput = [
  {
    name: "baseNew",
    placeholder: "Base",
    endAdornment: "°",
    select: "selectDirectionNew",
  },
];

export const resolvePrismInput = [
  { name: "prism", placeholder: "Prism", endAdornment: "cm/m" },
];

export const resolveBaseInput = [
  { name: "base", placeholder: "Base", endAdornment: "°" },
];
