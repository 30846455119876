import React from "react";
import { ReactComponent as LensIcon } from "../../assets/rotationLens.svg";
import { motion } from "framer-motion";
import { Ripple } from "primereact/ripple";

const RotationLens = ({ rotate = 0, onClick }) => {
  const rotationValue =
    rotate <= 90 ? Number(rotate) * -1 : (rotate - 180) * -1;

  return (
    <div
      className="lens flex justify-content-center align-items-center cursor-pointer p-ripple"
      style={{ height: "42px", width: "42px" }}
      onClick={onClick}
    >
      <motion.div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        animate={{
          transform: `rotate(${!isNaN(rotationValue) ? rotationValue : "0"}deg)`,
        }}
      >
        <LensIcon></LensIcon>
      </motion.div>
      <Ripple />
    </div>
  );
};

export default RotationLens;
