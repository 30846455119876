import * as yup from "yup";
import {
  axisValidation,
  rotationValidation,
  sphereCylinderValidation,
  vertexValidation,
} from "./schemaHelpers";

export const schema = yup
  .object({
    lensPowerSphereOD: sphereCylinderValidation,
    lensPowerSphereOS: sphereCylinderValidation,
    lensPowerCylinderOD: sphereCylinderValidation,
    lensPowerCylinderOS: sphereCylinderValidation,
    lensPowerAxisOD: axisValidation,
    lensPowerAxisOS: axisValidation,
    overRefractionSphereOD: sphereCylinderValidation,
    overRefractionSphereOS: sphereCylinderValidation,
    overRefractionCylinderOD: sphereCylinderValidation,
    overRefractionCylinderOS: sphereCylinderValidation,
    overRefractionAxisOD: axisValidation,
    overRefractionAxisOS: axisValidation,
    rotationOD: rotationValidation,
    rotationOS: rotationValidation,
    vertexOD: vertexValidation,
    vertexOS: vertexValidation,
  })
  .test(
    "cylinder-axis-relation",
    "If a cylinder value is provided, the related axis field must be filled, and vice versa.",
    function (value) {
      const {
        lensPowerCylinderOD,
        lensPowerAxisOD,
        lensPowerCylinderOS,
        lensPowerAxisOS,
        overRefractionCylinderOD,
        overRefractionCylinderOS,
        overRefractionAxisOD,
        overRefractionAxisOS,
      } = value || {};

      const validatePair = (cylinder, axis, cylinderPath, axisPath) => {
        if (cylinder && !axis) {
          return this.createError({
            path: axisPath,
            message: "This value is required",
          });
        }
        if (axis && !cylinder) {
          return this.createError({
            path: cylinderPath,
            message: "This value is required",
          });
        }
        return true;
      };

      let result = validatePair(
        lensPowerCylinderOD,
        lensPowerAxisOD,
        "lensPowerCylinderOD",
        "lensPowerAxisOD"
      );
      if (result !== true) return result;

      result = validatePair(
        lensPowerCylinderOS,
        lensPowerAxisOS,
        "lensPowerCylinderOS",
        "lensPowerAxisOS"
      );
      if (result !== true) return result;

      // Validate overRefraction pairs
      result = validatePair(
        overRefractionCylinderOD,
        overRefractionAxisOD,
        "overRefractionCylinderOD",
        "overRefractionAxisOD"
      );
      if (result !== true) return result;

      result = validatePair(
        overRefractionCylinderOS,
        overRefractionAxisOS,
        "overRefractionCylinderOS",
        "overRefractionAxisOS"
      );
      if (result !== true) return result;

      return true;
    }
  );
