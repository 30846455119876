const copyValueIfNecessary = (sourceField, targetField, setValue, data) => {
  if (data[sourceField] && !data[targetField]) {
    setValue(targetField, Number(data[sourceField]).toFixed(2));
    data[targetField] = data[sourceField];
  }

  if (!data[sourceField] && data[targetField]) {
    setValue(sourceField, Number(data[targetField]).toFixed(2));
    data[sourceField] = data[targetField];
  }
};
export const handleRadiusFields = (data, setValue) => {
  copyValueIfNecessary("radiusOld1OD", "radiusOld2OD", setValue, data);
  copyValueIfNecessary("radiusOld1OS", "radiusOld2OS", setValue, data);

  copyValueIfNecessary("radiusNew1OD", "radiusNew2OD", setValue, data);
  copyValueIfNecessary("radiusNew1OS", "radiusNew2OS", setValue, data);

  copyValueIfNecessary("radiusNew1OD", "radiusOld1OD", setValue, data);
  copyValueIfNecessary("radiusNew2OD", "radiusOld2OD", setValue, data);

  copyValueIfNecessary("radiusNew1OS", "radiusOld1OS", setValue, data);
  copyValueIfNecessary("radiusNew2OS", "radiusOld2OS", setValue, data);
};

export const calculateRotation = (value) =>
  value < 0
    ? -value
    : Number(value) > 0 && Number(value) <= 90
      ? -value
      : 180 - Number(value);
