import { calculateSections } from "../functions/diopter.js";
import { checkAxis } from "./check.js";
import { defaultFraction } from "./constant.js";
import { convertSpheroCylinder } from "./convert.js";
import { roundValue } from "./round.js";

/********************************************************************
 * Formats the provided value regarding fraction.
 ********************************************************************/
export function formatValue(value, fraction = defaultFraction) {
  const roundedResult = roundValue({ value, fraction });
  const roundedValue = roundedResult.rounding;

  let formattedValue =
    fraction <= 100 ? roundedValue.toFixed(2) : roundedValue.toString();

  return formattedValue;
}

/********************************************************************
 * Formats the axis value.
 ********************************************************************/
export function formatAxis(value) {
  return Math.round(value);
}

/********************************************************************
 * Formats the sphero-cylinder parameters.
 ********************************************************************/
export function formatSpheroCylinder({
  sphere = 0,
  cylinder = 0,
  axis = 0,
  fraction = defaultFraction,
  sign = "",
}) {
  const convert = convertSpheroCylinder({ sphere, cylinder, axis, sign });

  const sections = calculateSections({
    sphere: convert.sphere,
    cylinder: convert.cylinder,
  });
  const newCylinder = sections.section2 - sections.section1;

  // Round sections
  const roundSphere = roundValue({ value: sections.section1, fraction });
  const roundCylinder = roundValue({ value: newCylinder, fraction });

  // Find total spherocylindrical deviance for each of all possible rounding cases
  const case0 =
    Math.abs(roundSphere.deviancePositive) +
    Math.abs(roundCylinder.deviancePositive);
  const case1 =
    Math.abs(roundSphere.deviancePositive) +
    Math.abs(roundCylinder.devianceNegative);
  const case2 =
    Math.abs(roundSphere.devianceNegative) +
    Math.abs(roundCylinder.deviancePositive);
  const case3 =
    Math.abs(roundSphere.devianceNegative) +
    Math.abs(roundCylinder.devianceNegative);

  // Find case with the lowest spherocylindrical deviance
  const allCases = [case0, case1, case2, case3];
  allCases.sort((a, b) => a - b);
  const minCase = allCases[0];

  // Map cases with corresponding spherocylindrical combination
  sphere =
    minCase === case0 || minCase === case1
      ? roundSphere.valuePositive
      : roundSphere.valueNegative;
  cylinder =
    minCase === case0 || minCase === case2
      ? roundCylinder.valuePositive
      : roundCylinder.valueNegative;
  axis = checkAxis(convert.axis);

  const sectionsNew = calculateSections({ sphere, cylinder });
  const section1 = sectionsNew.section1;
  const section2 = sectionsNew.section2;

  // Format the values
  let sphereFormatted = formatValue(sphere, fraction);
  let cylinderFormatted = formatValue(cylinder, fraction);
  let axisFormatted = formatAxis(axis);
  let section1Formatted = formatValue(section1, fraction);
  let section2Formatted = formatValue(section2, fraction);

  // Handle special case for cylinder
  if (cylinderFormatted === "+0.00") {
    cylinderFormatted = "";
    axisFormatted = "";
  }

  return {
    sphere: sphereFormatted,
    cylinder: cylinderFormatted,
    axis: axisFormatted,
    section1: section1Formatted,
    section2: section2Formatted,
  };
}
