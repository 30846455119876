import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import Layout from "./components/layout/Layout.jsx";
import { createContext } from "react";
import { findFraction } from "./helpers/settingsHelpers";
import {
  HIGH_FRACTION,
  MEDIUM_FRACTION,
  SMALL_FRACTION,
  stabilisation,
} from "./constants/constants.js";
import "./customstyles.css";
import RgpCalculatorPage from "./pages/RgpCalculatorPage.jsx";
import VertexCalculatorPage from "./pages/VertexCalculatorPage.jsx";
import OtherCalculatorPage from "./pages/OtherCalculatorPage.jsx";
import CombinePrismCalculatorPage from "./pages/CombinePrismCalculatorPage.jsx";
import SettingsPage from "./pages/SettingsPage.jsx";
import _ from "lodash";
import SoftLensePage from "./pages/SoftLensPage.jsx";
import ResolvePrismPage from "./pages/ResolvePrismCalculatorPage.jsx";
import { manufacturers } from "./package/helpers/manufacturers.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const countrySettings = {
  DE: {
    language: "Browser language",
    contactLensOrientation: "Stabilisation (TABO)",
    diopterRounding: "±0.25 dpt",
    cylinderNotation: "Minus",
    curvatureUnit: "mm",
    prismUnit: "cm/m",
  },
  US: {
    language: "English",
    contactLensOrientation: "Lens rotation",
    diopterRounding: "±0.25 dpt",
    cylinderNotation: "Minus",
    curvatureUnit: "dpt",
    prismUnit: "pdpt",
  },
  CH: {
    language: "Browser language",
    contactLensOrientation: "Stabilisation (TABO)",
    diopterRounding: "±0.25 dpt",
    cylinderNotation: "Minus",
    curvatureUnit: "mm",
    prismUnit: "cm/m",
  },
  AT: {
    language: "Browser language",
    contactLensOrientation: "Stabilisation (TABO)",
    diopterRounding: "±0.25 dpt",
    cylinderNotation: "Minus",
    curvatureUnit: "mm",
    prismUnit: "cm/m",
  },
  Default: {
    language: "English",
    contactLensOrientation: "Lens rotation",
    diopterRounding: "±0.25 dpt",
    cylinderNotation: "Minus",
    curvatureUnit: "dpt",
    prismUnit: "pdpt",
  },
};

export const AppContext = createContext();

function App() {
  const [fraction, setFraction] = useState(
    getFractionFromLS(localStorage.getItem("fraction"))
  );
  const [cylinderNotation, setCylinderNotation] = useState(
    localStorage.getItem("cylinder notation") || "Minus"
  );
  const [curvatureUnit, setCurvatureUnit] = useState(
    localStorage.getItem("curvature unit") || "dpt"
  );
  const [prismUnit, setPrismUnit] = useState(
    localStorage.getItem("prism unit") || "pdpt"
  );
  const [selectedCountry, setSelectedCountry] = useState(
    localStorage.getItem("country") || ""
  );
  const [lensOrientation, setLensOrientation] = useState(
    localStorage.getItem("lens orientation") || stabilisation
  );
  const [manufacturer, setManufacturer] = useState(
    localStorage.getItem("manufacturer") || ""
  );

  const [filteredManufacturersList, setFilteredManufacturersList] = useState(
    manufacturers.filter(
      (manufacturer) =>
        manufacturer.categories.find(
          (category) => category === "Contact lenses"
        ) &&
        manufacturer.countries.find(
          (country) => country.toLowerCase() === selectedCountry.toLowerCase()
        )
    )
  );

  useEffect(() => {
    if (
      !filteredManufacturersList.find(
        (man) => man.manufacturer === manufacturer
      )
    ) {
      localStorage.setItem(
        "manufacturer",
        filteredManufacturersList[0]?.manufacturer || ""
      );
      setManufacturer(filteredManufacturersList[0]?.manufacturer);
    }
  }, [filteredManufacturersList, manufacturer]);

  useEffect(() => {
    async function fetchUserLocation() {
      try {
        const response = await fetch("https://get.geojs.io/v1/ip/country.json");
        const data = await response.json();
        const { country } = data;
        if (!selectedCountry) {
          setSelectedCountry(country);
          localStorage.setItem("country", country);
        }
      } catch (error) {
        // Intentionally empty - error is non-critical and does not affect the flow
      }
    }
    fetchUserLocation();
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry) {
      const settings =
        countrySettings[selectedCountry] || countrySettings.Default;
      setFraction(getFractionFromLS(localStorage.getItem("fraction")));
      setCylinderNotation(settings.cylinderNotation);
      setCurvatureUnit(settings.curvatureUnit);
      setPrismUnit(settings.prismUnit);
      setLensOrientation(settings.contactLensOrientation);

      const selectedFraction = findFraction(settings.diopterRounding);
      const fractionString = JSON.stringify(selectedFraction);

      localStorage.setItem("language", settings.language);
      localStorage.setItem("lens orientation", settings.contactLensOrientation);
      localStorage.setItem("fraction", fractionString);
      localStorage.setItem("cylinder notation", settings.cylinderNotation);
      localStorage.setItem("curvature unit", settings.curvatureUnit);
      localStorage.setItem("prism unit", settings.prismUnit);
    }
  }, [selectedCountry]);

  function getFractionFromLS(jsonString) {
    try {
      const parsedObject = JSON.parse(jsonString);
      return (
        [SMALL_FRACTION, MEDIUM_FRACTION, HIGH_FRACTION].find((fraction) =>
          _.isEqual(parsedObject, fraction)
        ) || HIGH_FRACTION
      );
    } catch (error) {
      return HIGH_FRACTION;
    }
  }

  return (
    <AppContext.Provider
      value={{
        fraction,
        setFraction,
        cylinderNotation,
        setCylinderNotation,
        curvatureUnit,
        setCurvatureUnit,
        selectedCountry,
        setSelectedCountry,
        prismUnit,
        setPrismUnit,
        lensOrientation,
        setLensOrientation,
        manufacturer,
        setManufacturer,
        filteredManufacturersList,
        setFilteredManufacturersList,
      }}
    >
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<VertexCalculatorPage />} />
          <Route path="/rgp" element={<RgpCalculatorPage />} />
          <Route path="/vertex" element={<VertexCalculatorPage />} />
          <Route path="/other" element={<OtherCalculatorPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="soft-lens" element={<SoftLensePage />} />
          <Route path="combine-prism" element={<CombinePrismCalculatorPage />} />
          <Route path="/resolve-prism" element={<ResolvePrismPage />} />
        </Route>
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
