import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import BtnGroup from "../BtnGroup";
import { useForm } from "react-hook-form";
import { rgplens } from "../../package";
import { AppContext } from "../../App";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "../../helpers/rgpSchema";
import ResultTable from "../ResultTable";
import ResultItem from "../ResultItem";
import { roundNumberFromString } from "../../helpers/formatHelpers";
import { handleRadiusFields } from "../../helpers/rgpHelpers";
import { parseFormData } from "../../helpers/vertexHelpers";
import { DEFAULT_VERTEX } from "../../constants/vertexConstants";
import { manufacturers } from "../../package/helpers/manufacturers.js";
import { useMediaQuery } from "react-responsive";
import ResultsModal from "../ResultsModal.jsx";
import RefractionInputs from "../inputs/RefractionInputs.jsx";
import RotationAndVertexInputs from "../inputs/RotationAndVertexInputs.jsx";
import OverRefractionInputs from "../inputs/OverRefractionInputs.jsx";
import VertexInputs from "../inputs/VertexInputs.jsx";
import RotationInputs from "../inputs/RotationInputs.jsx";
import ParametersInputs from "../inputs/ParametersInputs.jsx";
import { lensPowerAxisInput, lensPowerSphereCylinderInputs, parametersNewInputs, parametersOldInputs } from "../../constants/constants.js";
import { notify } from "../../helpers/toast.js";

const RgpCalculator = () => {
  const [openPanel, setOpenPanel] = useState("Lens power");
  const [visible, setVisible] = useState(false);
  const [visibleResults, setVisibleResults] = useState(false);
  const [resultP1, setResultP1] = useState([]);
  const [resultP2, setResultP2] = useState([]);
  const [isFirstSubmit, setIsFirstSubmit] = useState(true);
  const [isCalculated, setIsCalculated] = useState(false);
  const [isCleared, setIsCleared] = useState(false);
  const [transition, setTransition] = useState("");
  const [formTransition, setFormTransition] = useState("");
  const [resultTransition, setResultTransition] = useState("");
  const [previousFraction, setPreviousFraction] = useState(null);
  const [previousSign, setPreviousSign] = useState(null);
  const [isMaterialCopied, setIsMaterialCopied] = useState({
    OD: false,
    OS: false,
  });
  const [isResultSkipped, setIsResultSkipped] = useState(false);
  const [isRadiusNew1ODChanged, setIsRadiusNew1ODChanged] = useState(false);
  const [isRadiusNew2ODChanged, setIsRadiusNew2ODChanged] = useState(false);
  const [isRadiusNew1OSChanged, setIsRadiusNew1OSChanged] = useState(false);
  const [isRadiusNew2OSChanged, setIsRadiusNew2OSChanged] = useState(false);
  const [isRGPAir, setIsRGPAir] = useState(false);
  const [materialManufacturer, setMaterialManufacturer] = useState(null);
  const [isChangeManufacturerClicked, setIsChangeManufacturerClicked] = useState(false);
  const [changeManufacturerCount, setChangeManufacturerCount] = useState(0);
  const [inputCardWidth1, setInputCardWidth1] = useState(0);
  const [inputCardWidth2, setInputCardWidth2] = useState(0);

  const inputCardRef1 = useRef(null);
  const inputCardRef2 = useRef(null);

  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 979 });
  const showMenuHavbar = useMediaQuery({ maxWidth: 1199 });

  const togglePanel = (title) => {
    setOpenPanel((prev) => (prev === title ? null : title));
  };

  const { fraction, cylinderNotation, lensOrientation, manufacturer: chosenManufacturer } = useContext(AppContext);
  const [options, setOptions] = useState(manufacturers.find((man) => man.manufacturer === chosenManufacturer)?.materials || []);

  const formRef = useRef(null);

  useEffect(() => {
    const selectedManufacturer = manufacturers.find((manufacturer) => manufacturer.manufacturer === chosenManufacturer);
    if (selectedManufacturer) {
      setMaterialManufacturer(selectedManufacturer);
      setOptions(selectedManufacturer.materials);
    }
  }, [chosenManufacturer]);

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rotationOD: 0,
      rotationOS: 0,
      vertexOD: DEFAULT_VERTEX.toFixed(1),
      vertexOS: DEFAULT_VERTEX.toFixed(1),
    },
  });

  const rotationOD = watch("rotationOD");
  const rotationOS = watch("rotationOS");
  const materialOldODValue = watch("materialOldOD");
  const materialOldOSValue = watch("materialOldOS");
  const sphereOldODValue = watch("lensPowerSphereOD");
  const sphereOldOSValue = watch("lensPowerSphereOS");
  const cylinderOldODValue = watch("lensPowerCylinderOD");
  const cylinderOldOSValue = watch("lensPowerCylinderOS");
  const axisOldODValue = watch("lensPowerAxisOD");
  const axisOldOSValue = watch("lensPowerAxisOS");
  const sectionOld1ODValue = watch("sectionOld1OD");
  const sectionOld2ODValue = watch("sectionOld2OD");
  const sectionOld2OSValue = watch("sectionOld2OS");
  const sectionOld1OSValue = watch("sectionOld1OS");

  const handleCalculations = useCallback(
    (data) => {
      const fields = [
        "radiusOld1",
        "radiusOld2",
        "radiusNew1",
        "radiusNew2",
        "vertex",
        "rotation",
        "lensPowerSphere",
        "lensPowerCylinder",
        "lensPowerAxis",
        "overRefractionSphere",
        "overRefractionCylinder",
        "overRefractionAxis",
        "sectionOld1",
        "sectionOld2",
        "sectionNew1",
        "sectionNew2",
      ];

      const { OD, OS } = parseFormData(data, fields);
      const resultOD = !isRGPAir
        ? rgplens.calculateRGP({
            r1Old: OD.radiusOld1,
            r2Old: OD.radiusOld2,
            sphereOld: OD.lensPowerSphere,
            cylinderOld: OD.lensPowerCylinder,
            axisOld: OD.lensPowerAxis,
            nOld: data.materialOldOD?.n || 1.443,
            rotation: OD.rotation,
            sphereAR: OD.overRefractionSphere,
            cylinderAR: OD.overRefractionCylinder,
            axisAR: OD.overRefractionAxis,
            vertex: OD.vertex,
            r1New: OD.radiusNew1,
            r2New: OD.radiusNew2,
            nNew: data.materialNewOD?.n || 1.443,
            fraction: fraction.value,
            sign: cylinderNotation === "Minus" ? "-" : "+",
          })
        : rgplens.calculateRGPAirSections({
            r1Old: OD.radiusOld1,
            r2Old: OD.radiusOld2,
            section1Old: OD.sectionOld1,
            section2Old: OD.sectionOld2,
            axisOld: OD.lensPowerAxis,
            nOld: data.materialOldOD?.n || 1.443,
            rotation: OD.rotation,
            sphereAR: OD.overRefractionSphere,
            cylinderAR: OD.overRefractionCylinder,
            axisAR: OD.overRefractionAxis,
            vertex: OD.vertex,
            r1New: OD.radiusNew1,
            r2New: OD.radiusNew2,
            nNew: data.materialNewOD?.n || 1.443,
            fraction: fraction.value,
            sign: cylinderNotation === "Minus" ? "-" : "+",
          });
      const resultOS = !isRGPAir
        ? rgplens.calculateRGP({
            r1Old: OS.radiusOld1,
            r2Old: OS.radiusOld2,
            sphereOld: OS.lensPowerSphere,
            cylinderOld: OS.lensPowerCylinder,
            axisOld: OS.lensPowerAxis,
            nOld: data.materialOldOS?.n || 1.443,
            rotation: OS.rotation,
            sphereAR: OS.overRefractionSphere,
            cylinderAR: OS.overRefractionCylinder,
            axisAR: OS.overRefractionAxis,
            vertex: OS.vertex,
            r1New: OS.radiusNew1,
            r2New: OS.radiusNew2,
            nNew: data.materialNewOS?.n || 1.443,
            fraction: fraction.value,
            sign: cylinderNotation === "Minus" ? "-" : "+",
          })
        : rgplens.calculateRGPAirSections({
            r1Old: OS.radiusOld1,
            r2Old: OS.radiusOld2,
            section1Old: OS.sectionOld1,
            section2Old: OS.sectionOld2,
            axisOld: OS.lensPowerAxis,
            nOld: data.materialOldOS?.n || 1.443,
            rotation: OS.rotation,
            sphereAR: OS.overRefractionSphere,
            cylinderAR: OS.overRefractionCylinder,
            axisAR: OS.overRefractionAxis,
            vertex: OS.vertex,
            r1New: OS.radiusNew1,
            r2New: OS.radiusNew2,
            nNew: data.materialNewOD?.n || 1.443,
            fraction: fraction.value,
            sign: cylinderNotation === "Minus" ? "-" : "+",
          });

      !isRGPAir
        ? setResultP1([
            {
              eye: "OD",
              sphere: roundNumberFromString(resultOD.eyeFormattedSphere),
              cylinder: roundNumberFromString(resultOD.eyeFormattedCylinder),
              axis: resultOD.eyeFormattedAxis,
            },
            {
              eye: "OS",
              sphere: roundNumberFromString(resultOS.eyeFormattedSphere),
              cylinder: roundNumberFromString(resultOS.eyeFormattedCylinder),
              axis: resultOS.eyeFormattedAxis,
            },
          ])
        : setResultP1([
            {
              eye: "OD",
              section1: resultOD.airSection1,
              section2: resultOD.airSection2,
            },
            {
              eye: "OS",
              section1: resultOS.airSection1,
              section2: resultOS.airSection2,
            },
          ]);

      setResultP2([
        {
          eye: "OD",
          radius1: data.radiusNew1OD,
          radius2: data.radiusNew2OD,
          material: data.materialNewOD?.name,
        },
        {
          eye: "OS",
          radius1: data.radiusNew1OS,
          radius2: data.radiusNew2OS,
          material: data.materialNewOS?.name,
        },
      ]);
      if (
        (isNaN(roundNumberFromString(resultOS.eyeFormattedSphere)) && isNaN(roundNumberFromString(resultOS.eyeFormattedCylinder))) ||
        (isNaN(roundNumberFromString(resultOD.eyeFormattedSphere)) && isNaN(roundNumberFromString(resultOD.eyeFormattedCylinder)))
      ) {
        setIsResultSkipped(true);
      } else {
        setIsResultSkipped(false);
      }
    },
    [cylinderNotation, fraction.value, isRGPAir]
  );

  useEffect(() => {
    if (isChangeManufacturerClicked)
      setIsMaterialCopied({
        OD: false,
        OS: false,
      });
  }, [isChangeManufacturerClicked]);

  useEffect(() => {
    if (!isCalculated) return;

    const formData = getValues();
    handleCalculations(formData);
  }, [fraction.value, getValues, handleCalculations, isCalculated]);

  useEffect(() => {
    if ((previousFraction && previousFraction !== fraction) || (previousSign && previousSign !== cylinderNotation)) {
      setResultTransition("");
      setTimeout(() => {
        setResultTransition("ripple");
      }, 200);
    }
    setPreviousFraction(fraction);
    setPreviousSign(cylinderNotation);
  }, [cylinderNotation, fraction, previousFraction, previousSign]);

  useEffect(() => {
    if (isCleared) {
      setTransition("transition-out");
      setIsCleared(false);
    }
    setTimeout(() => {
      setTransition("hidden");
    }, 200);
  }, [isCleared]);

  useEffect(() => {
    if (isChangeManufacturerClicked) {
      setFormTransition("transition-out");

      setTimeout(() => {
        setFormTransition("transition");
      }, 200);
    }
  }, [isChangeManufacturerClicked, isRGPAir]);

  useEffect(() => {
    if (!isMaterialCopied.OD && materialOldODValue) {
      setValue("materialNewOD", materialOldODValue);
      setIsMaterialCopied((prev) => ({ ...prev, OD: true }));
    }

    if (!isMaterialCopied.OS && materialOldOSValue) {
      setValue("materialNewOS", materialOldOSValue);
      setIsMaterialCopied((prev) => ({ ...prev, OS: true }));
    }
  }, [isMaterialCopied, setValue, materialOldODValue, materialOldOSValue, options]);

  useEffect(() => {
    if (materialManufacturer?.notation === "RGPAirSections") {
      setIsRGPAir(true);
      setIsCalculated(false);
      reset();
    } else {
      setIsRGPAir(false);
      setIsCalculated(false);
      reset();
    }
  }, [materialManufacturer, reset]);

  const onSubmit = useCallback(
    (data) => {
      if (
        !sphereOldODValue &&
        !sphereOldOSValue &&
        !cylinderOldODValue &&
        !cylinderOldOSValue &&
        !axisOldODValue &&
        !axisOldOSValue &&
        !sectionOld1ODValue &&
        !sectionOld1OSValue &&
        !sectionOld2ODValue &&
        !sectionOld2OSValue
      ) {
        notify(t("toastInfo"));
        setTimeout(() => {
          const firstInput = document.querySelector('[tabIndex="1"]');
          if (firstInput) {
            firstInput.focus();
          }
        }, 100);

        return;
      }

      handleRadiusFields(data, setValue);
      setIsCalculated(true);
      handleCalculations(data);
      setVisibleResults(true);

      if (!sphereOldODValue && axisOldODValue && cylinderOldODValue) {
        setValue("lensPowerSphereOD", "+0.00");
      }
      if (!sphereOldOSValue && axisOldOSValue && cylinderOldOSValue) {
        setValue("lensPowerSphereOS", "+0.00");
      }

      if (isFirstSubmit) {
        setTransition("hidden");
        setTransition("transition");
        setIsFirstSubmit(false);
      } else {
        setTransition("");
        setResultTransition("");
        setTimeout(() => {
          setResultTransition("ripple");
        }, 200);
      }
    },
    [
      sphereOldODValue,
      sphereOldOSValue,
      cylinderOldODValue,
      cylinderOldOSValue,
      axisOldODValue,
      axisOldOSValue,
      sectionOld1ODValue,
      sectionOld1OSValue,
      sectionOld2ODValue,
      sectionOld2OSValue,
      setValue,
      handleCalculations,
      isFirstSubmit,
      t,
    ]
  );

  const handleRotationClick = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        if (formRef.current && !Object.keys(errors).length) {
          const inputs = formRef.current.querySelectorAll("input");
          inputs.forEach((input) => input.blur());
        }
      }
    },
    [errors]
  );

  const updateCardWidths = () => {
    if (inputCardRef1.current) {
      setInputCardWidth1(inputCardRef1.current.clientWidth);
    }
    if (inputCardRef2.current) {
      setInputCardWidth2(inputCardRef2.current.clientWidth);
    }
  };

  useEffect(() => {
    updateCardWidths();
    window.addEventListener("resize", updateCardWidths);
    return () => {
      window.removeEventListener("resize", updateCardWidths);
    };
  }, []);

  const createResultItems = (valueSet, isFirst) => {
    if (isNaN(valueSet.sphere) && isNaN(valueSet.cylinder) && isNaN(valueSet.axis)) {
      return;
    }
    if (valueSet.cylinder === "+0.00") {
      valueSet.cylinder = "";
      valueSet.axis = "";
    }

    return (
      <>
        <ResultItem
          resultTransition={resultTransition}
          value={!isNaN(valueSet.sphere) ? valueSet.sphere : ""}
          label={t("Sphere")}
          endAdornment="dpt"
          isFirst={isFirst}
          isResultSkipped={isResultSkipped}
          resultItemWidth="res-sphere-cylinder"
        />
        <ResultItem
          resultTransition={resultTransition}
          value={!isNaN(valueSet.cylinder) ? valueSet.cylinder : ""}
          label={t("Cylinder")}
          endAdornment="dpt"
          isFirst={isFirst}
          isResultSkipped={isResultSkipped}
          resultItemWidth="res-sphere-cylinder"
        />
        <ResultItem
          resultTransition={resultTransition}
          value={valueSet.axis}
          label={t("Axis")}
          endAdornment="°"
          isFirst={isFirst}
          isResultSkipped={isResultSkipped}
          resultItemWidth="res-axis"
        />
      </>
    );
  };

  useEffect(() => {
    setIsMaterialCopied({ OD: false, OS: false });
  }, [changeManufacturerCount]);

  const createRGPAirResultItems = (valueSet, isFirst) => {
    if (isNaN(valueSet.section1) && isNaN(valueSet.section2)) {
      return;
    }
    return (
      <div style={{ width: `${inputCardWidth1}px`, display: "flex", gap: "10px" }}>
        <ResultItem
          resultTransition={resultTransition}
          value={!isNaN(valueSet.section1) ? valueSet.section1.toFixed(2) : ""}
          label={t("Section 1")}
          endAdornment="dpt"
          isFirst={isFirst}
          isResultSkipped={isResultSkipped}
          resultItemWidth="res-section"
        />
        <ResultItem
          resultTransition={resultTransition}
          value={!isNaN(valueSet.section2) ? valueSet.section2.toFixed(2) : ""}
          label={t("Section 2")}
          endAdornment="dpt"
          isFirst={isFirst}
          isResultSkipped={isResultSkipped}
          resultItemWidth="res-section"
        />
      </div>
    );
  };

  const createResultItemsP2 = (valueSet, isFirst) => {
    if (!valueSet.radius1 && !valueSet.radius2) {
      return;
    }
    return (
      <>
        <ResultItem
          resultTransition={resultTransition}
          value={Number(valueSet.radius1).toFixed(2)}
          label={"Radius 1"}
          endAdornment="mm"
          isFirst={isFirst}
          isResultSkipped={isResultSkipped}
          resultItemWidth="res-radius"
        />
        <ResultItem
          resultTransition={resultTransition}
          value={Number(valueSet.radius2).toFixed(2)}
          label={"Radius 2"}
          endAdornment="mm"
          isFirst={isFirst}
          isResultSkipped={isResultSkipped}
          resultItemWidth="res-radius"
        />
        {options.length !== 0 && (
          <ResultItem
            resultTransition={resultTransition}
            value={valueSet.material || options[0].name}
            label={"Material"}
            isFirst={isFirst}
            isResultSkipped={isResultSkipped}
            isMaterial={true}
            resultItemWidth="material-result"
          />
        )}
      </>
    );
  };

  return (
    <div className="general-container flex flex-column gap-5 w-max">
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} onKeyUp={(e) => handleKeyPress(e)} className="flex flex-column  justify-content-center gap-5  ">
        <div className="cards-container flex flex-column  gap-2">
          <div className="cards-container flex gap-2 flex-wrap">
            <div className="add-wrap" ref={inputCardRef1} id="refraction-input">
              <RefractionInputs
                isRGPAir={isRGPAir}
                control={control}
                errors={errors}
                sphereCylinderODIndex={["1", "2"]}
                axisODIndex={["3"]}
                sphereCylinderOSIndex={["8", "9"]}
                axisOSIndex={["10"]}
                sphereCylinderInputs={lensPowerSphereCylinderInputs}
                axisInput={lensPowerAxisInput}
                formTransition={formTransition}
                onToggle={togglePanel}
                isOpen={openPanel}
              ></RefractionInputs>
            </div>
            <div className="add-wrap" ref={inputCardRef2}>
              <ParametersInputs
                control={control}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
                options={options}
                title={t("Lens parameters")}
                isRadiusNew1ODChanged={isRadiusNew1ODChanged}
                isRadiusNew1OSChanged={isRadiusNew1OSChanged}
                isRadiusNew2ODChanged={isRadiusNew2ODChanged}
                isRadiusNew2OSChanged={isRadiusNew2OSChanged}
                setIsRadiusNew1ODChanged={setIsRadiusNew1ODChanged}
                setIsRadiusNew1OSChanged={setIsRadiusNew1OSChanged}
                setIsRadiusNew2ODChanged={setIsRadiusNew2ODChanged}
                setIsRadiusNew2OSChanged={setIsRadiusNew2OSChanged}
                parametersODIndexes={["4", "5"]}
                materialODIndex={"6"}
                parametersOSIndexes={["11", "12"]}
                materialOSIndex={"13"}
                inputs={parametersOldInputs}
                isOld={true}
                onToggle={togglePanel}
                isOpen={openPanel}
                hideTitle={true}
                commonEye={true}
              ></ParametersInputs>
            </div>
            {!isTabletOrMobile && (
              <RotationInputs
                control={control}
                errors={errors}
                setValue={setValue}
                visible={visible}
                setVisible={setVisible}
                handleRotationClick={handleRotationClick}
                lensOrientation={lensOrientation}
                rotationOD={rotationOD}
                rotationOS={rotationOS}
                rotationODIndex={["7"]}
                rotationOSIndex={["14"]}
                onToggle={togglePanel}
                isOpen={openPanel}
              ></RotationInputs>
            )}
          </div>
          {isTabletOrMobile && (
            <RotationAndVertexInputs
              isTabletOrMobile={isTabletOrMobile}
              control={control}
              errors={errors}
              lensOrientation={lensOrientation}
              rotationOD={rotationOD}
              rotationOS={rotationOS}
              handleRotationClick={handleRotationClick}
              setValue={setValue}
              visible={visible}
              setVisible={setVisible}
              onToggle={togglePanel}
              isOpen={openPanel}
            ></RotationAndVertexInputs>
          )}
          <div className="cards-container flex gap-2  flex-wrap">
            <OverRefractionInputs
              control={control}
              errors={errors}
              sphereCylinderODIndex={["15", "16"]}
              axisODIndex={["17"]}
              sphereCylinderOSIndex={["22", "23"]}
              axisOSIndex={["24"]}
              onToggle={togglePanel}
              isOpen={openPanel}
            ></OverRefractionInputs>

            <ParametersInputs
              control={control}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              options={options}
              title={t("New parameters")}
              isRadiusNew1ODChanged={isRadiusNew1ODChanged}
              isRadiusNew1OSChanged={isRadiusNew1OSChanged}
              isRadiusNew2ODChanged={isRadiusNew2ODChanged}
              isRadiusNew2OSChanged={isRadiusNew2OSChanged}
              setIsRadiusNew1ODChanged={setIsRadiusNew1ODChanged}
              setIsRadiusNew1OSChanged={setIsRadiusNew1OSChanged}
              setIsRadiusNew2ODChanged={setIsRadiusNew2ODChanged}
              setIsRadiusNew2OSChanged={setIsRadiusNew2OSChanged}
              parametersODIndexes={["18", "19"]}
              materialODIndex={"20"}
              parametersOSIndexes={["25", "26"]}
              materialOSIndex={"27"}
              inputs={parametersNewInputs}
              isOld={false}
              onToggle={togglePanel}
              isOpen={openPanel}
            ></ParametersInputs>

            {!isTabletOrMobile && <VertexInputs control={control} errors={errors} vertexODIndex={["21"]} vertexOSIndex={["28"]}></VertexInputs>}
          </div>
        </div>
        <BtnGroup
          reset={reset}
          setIsCleared={setIsCleared}
          setIsChangeManufacturerClicked={setIsChangeManufacturerClicked}
          setChangeManufacturerCount={setChangeManufacturerCount}
          setValue={setValue}
          hideSetupBtns={showMenuHavbar}
        ></BtnGroup>
      </form>

      {isCalculated && !isTabletOrMobile && (
        <div className="relative">
          <div className={`res-table gap-2 flex-wrap align-items-end ${transition}`}>
            <ResultTable values={resultP1} createResultItems={!isRGPAir ? createResultItems : createRGPAirResultItems} width={inputCardWidth1} />
            <ResultTable values={resultP2} createResultItems={createResultItemsP2} isSecondTable={true} width={inputCardWidth2} />
          </div>
        </div>
      )}
      {isTabletOrMobile && (
        <ResultsModal
          visible={visibleResults}
          setVisible={setVisibleResults}
          table1={<ResultTable values={resultP1} createResultItems={!isRGPAir ? createResultItems : createRGPAirResultItems} />}
          table2={<ResultTable values={resultP2} createResultItems={createResultItemsP2} />}
        ></ResultsModal>
      )}
    </div>
  );
};

export default RgpCalculator;
