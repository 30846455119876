import { checkAxis, checkPositive } from "../helpers/check.js";
import { defaultFraction } from "../helpers/constant.js";
import { formatSpheroCylinder } from "../helpers/format.js";
import { calculateSections, calculateSpheroCylinder } from "./diopter.js";

/********************************************************************
 * Calculate vertex for a single section based on the vertex distances.
 ********************************************************************/
export function calculateVertexSingle({ section, vertexOld, vertexNew }) {

  vertexOld = checkPositive(vertexOld);
  vertexNew = checkPositive(vertexNew);

  const vertexDistance = vertexOld - vertexNew;
  const result = section !== 0 ? 1 / (1 / section - vertexDistance / 1000) : 0;

  return result;
}

/********************************************************************
 * Calculate vertex compensation for spherical and cylindrical lens values.
 ********************************************************************/
export function calculateVertex({
  sphere = 0,
  cylinder = 0,
  axis = 0,
  vertexOld = 0,
  vertexNew = 0,
  fraction = defaultFraction,
  sign = "",
}) {

  axis = checkAxis(axis);
  vertexOld = checkPositive(vertexOld);
  vertexNew = checkPositive(vertexNew);

  const calculation = calculateSections({ sphere, cylinder });

  const section1 = calculateVertexSingle({ section: calculation.section1, vertexOld, vertexNew });
  const section2 = calculateVertexSingle({ section: calculation.section2, vertexOld, vertexNew });

  const result = calculateSpheroCylinder({ section1, section2 });

  const format = formatSpheroCylinder({
    sphere: result.sphere,
    cylinder: result.cylinder,
    axis,
    fraction,
    sign,
  });

  // Return both raw and formatted results
  return {
    sphere: result.sphere,
    cylinder: result.cylinder,
    axis: axis,
    formattedSphere: format.sphere,
    formattedCylinder: format.cylinder,
    formattedAxis: format.axis,
  };
}