export const DEFAULT_VERTEX = 12;

export const sphereCylinderInputs = [
  { name: "sphere", placeholder: "Sphere", endAdornment: "dpt" },
  { name: "cylinder", placeholder: "Cylinder", endAdornment: "dpt" },
];

export const axisInput = [
  { name: "axis", placeholder: "Axis", endAdornment: "°" },
];

export const vertexInputs = [
  { name: "vertexOld", placeholder: "Vertex old", endAdornment: "mm" },
  { name: "vertexNew", placeholder: "Vertex new", endAdornment: "mm" },
];
