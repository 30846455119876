import { checkAxis, checkPositive } from "../helpers/check.js";
import { defaultFraction } from "../helpers/constant.js";
import { formatSpheroCylinder } from "../helpers/format.js";
import { calculateCrosscylinder } from "./crosscylinder.js";
import { calculateVertex } from "./vertex.js";

/********************************************************************
 * Calculate Lars (rotation adjustment for lens axis)
 ********************************************************************/
export function calculateLars({ axis, rotation }) {
  if (rotation >= 0 && rotation < 90) {
    axis = axis - rotation;
  } else {
    axis = 180 - rotation + axis;
  }
  return checkAxis(axis);
}

/********************************************************************
 * Calculate soft lens properties
 ********************************************************************/
export function calculateSoftLens({
  sphere1 = 0,
  cylinder1 = 0,
  axis1 = 0,
  sphere2 = 0,
  cylinder2 = 0,
  axis2 = 0,
  rotation = 0,
  vertex = 0,
  fraction = defaultFraction,
  sign = "",
}) {
  // Validate input and ensure axes and rotation are within proper ranges
  axis1 = checkAxis(axis1);
  axis2 = checkAxis(axis2);
  rotation = checkAxis(rotation);
  vertex = checkPositive(vertex);

  // Calculate new axis after rotation
  const newAxis = axis1 + rotation;

  // Calculate vertex compensation for second lens
  const newVertex = calculateVertex({
    sphere: sphere2,
    cylinder: cylinder2,
    axis: axis2,
    vertexOld: vertex,
    vertexNew: 0,
  });

  // Calculate cross-cylinder result
  const result = calculateCrosscylinder({
    sphere1: sphere1,
    cylinder1: cylinder1,
    axis1: newAxis,
    sphere2: newVertex.sphere,
    cylinder2: newVertex.cylinder,
    axis2: newVertex.axis,
  });

  // Adjust axis based on rotation using Lars calculation
  const axisResult = calculateLars({ axis: result.axis, rotation });

  // Format the resulting sphere, cylinder, and axis values
  const formatted = formatSpheroCylinder({
    sphere: result.sphere,
    cylinder: result.cylinder,
    axis: axisResult,
    fraction,
    sign,
  });

  return {
    sphere: result.sphere,
    cylinder: result.cylinder,
    axis: axisResult,
    formattedSphere: formatted.sphere,
    formattedCylinder: formatted.cylinder,
    formattedAxis: formatted.axis,
  };
}