import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import MaterialSelect from "./rgpCalculator/MaterialSelect";

const StyledInput = forwardRef(
  (
    {
      value = "",
      onChange,
      onBlur,
      placeholder,
      name,
      error,
      endAdornment,
      className,
      tabOrderIndex,
      inputMode,
      select,
      control,
      selectName,
    },
    ref
  ) => {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
      if (inputRef.current && tabOrderIndex === "1") {
        setTimeout(() => inputRef.current.focus(), 200);
      }
    }, [tabOrderIndex]);

    const borderStyle = useMemo(() => {
      if (error)
        return isFocused ? "input-invalid box-shadow-error" : "input-invalid";
      if (isFocused) return "input-container-focused box-shadow";
      if (isHovered) return "input-container-focused";
      return "input-container";
    }, [error, isFocused, isHovered]);

    const handleFocus = useCallback(() => setIsFocused(true), []);
    const handleBlur = useCallback(
      (event) => {
        if (onBlur) {
          onBlur(event);
        }
        setIsFocused(false);
      },
      [onBlur]
    );

    return (
      <div className="flex flex-column align-items-center relative">
        <div
          className={`${borderStyle} flex align-items-center border-round-md`}
          style={{ width: "100%" }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <label htmlFor={name} className="relative">
            <InputText
              value={value}
              ref={(el) => {
                inputRef.current = el;
                if (typeof ref === "function") ref(el);
                else if (ref) ref.current = el;
              }}
              id={name}
              autoFocus={tabOrderIndex === "1"}
              onChange={onChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              className={`input border border-round-left-md border-noround-right border-right-none p-inputtext-sm ${className}`}
              autoComplete="off"
              tabIndex={tabOrderIndex}
              inputMode={inputMode}
            />
            {placeholder && (
              <label htmlFor={name} className="absolute font-medium floated-label">
                {t(placeholder)}
              </label>
            )}
          </label>
          {endAdornment && (
            <div className="flex align-items-center">
              <span className="adornment-text">{endAdornment}</span>
              {select && (
                <MaterialSelect name={selectName} control={control} options={select} tabOrderIndex={tabOrderIndex} hideLabel={true} inlineType={true} />
              )}
              {placeholder && (
                <label htmlFor={name} className="absolute font-medium floated-label">
                  {t(placeholder)}
                </label>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default StyledInput;
