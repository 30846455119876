import { check2Axis, checkPositive } from "../helpers/check.js";
import { deg2rad, rad2deg } from "../helpers/convert.js";
import { calculateCrosscylinderComponent } from "./crosscylinder.js";
import { calculateVertex } from "./vertex.js";

/********************************************************************
 * Calculate induced astigmatism
 ********************************************************************/
export function calculateInducedAstigmatism({ r1, r2, n1, n2 }) {
  r1 = checkPositive(r1);
  r2 = checkPositive(r2);
  n1 = checkPositive(n1);
  n2 = checkPositive(n2);

  const cylinder = -(1000 / r2 - 1000 / r1) * (n2 - n1);
  return {
    sphere: 0,
    cylinder,
    axis: 180,
  };
}

/********************************************************************
 * Calculate external astigmatism
 ********************************************************************/
export function calculateExternalAstigmatism({ r1, r2, axisR1, axisR2 }) {
  const axes = check2Axis({ axisR1, axisR2 });
  r1 = checkPositive(r1);
  r2 = checkPositive(r2);

  const externalAstigmatism = -336 * Math.abs(1 / r2 - 1 / r1);
  const axis = r1 >= r2 ? axes.axisR1 : axes.axisR2;

  return {
    externalAstigmatism,
    axis,
  };
}

/********************************************************************
 * Calculate internal astigmatism
 ********************************************************************/
export function calculateInternalAstigmatism({
  sphere = 0,
  cylinder = 0,
  axis = 0,
  vertex = 0,
  r1,
  r2,
  axisR1,
  axisR2,
}) {
  const axes = check2Axis({ axisR1, axisR2 });
  vertex = checkPositive(vertex);
  r1 = checkPositive(r1);
  r2 = checkPositive(r2);

  const vertexNew = calculateVertex({
    sphere,
    cylinder,
    axis,
    vertexOld: vertex,
    vertexNew: 0,
  });

  const external = calculateExternalAstigmatism({
    r1,
    r2,
    axisR1: axes.axisR1,
    axisR2: axes.axisR2,
  });

  const result = calculateCrosscylinderComponent({
    sphere1: 0,
    cylinder1: external.externalAstigmatism,
    axis1: external.axis,
    sphere3: vertexNew.sphere,
    cylinder3: vertexNew.cylinder,
    axis3: vertexNew.axis,
  });

  return {
    sphere: result.sphere,
    cylinder: result.cylinder,
    axis: result.axis,
  };
}

/********************************************************************
 * Calculate lens in air to eye
 ********************************************************************/
export function calculateLensAir2Eye({
  r1,
  r2,
  sphere = 0,
  cylinder = 0,
  axis = 0,
  n1,
  n2,
}) {
  r1 = checkPositive(r1);
  r2 = checkPositive(r2);
  n1 = checkPositive(n1);
  n2 = checkPositive(n2);

  // Induced astigmatism
  const induced = calculateInducedAstigmatism({ r1, r2, n1, n2 });

  // Combination of spherical-cylinder at the eye
  const result = calculateCrosscylinderComponent({
    sphere1: induced.sphere,
    cylinder1: induced.cylinder,
    axis1: induced.axis,
    sphere3: sphere,
    cylinder3: cylinder,
    axis3: axis,
  });

  return {
    sphere: result.sphere,
    cylinder: result.cylinder,
    axis: result.axis,
  };
}

/********************************************************************
 * Calculate matrix from diopters
 ********************************************************************/
export function calculateDiopter2matrix({
  sphere1 = 0,
  cylinder1 = 0,
  axis1 = 0,
  sphere2 = 0,
  cylinder2 = 0,
  axis2 = 0,
}) {
  const Fx1 = sphere1 + cylinder1 * Math.sin(deg2rad(axis1)) ** 2;
  const Fy1 = sphere1 + cylinder1 * Math.cos(deg2rad(axis1)) ** 2;
  const Fxy1 = -cylinder1 * Math.sin(deg2rad(axis1)) * Math.cos(deg2rad(axis1));

  const Fx2 = sphere2 + cylinder2 * Math.sin(deg2rad(axis2)) ** 2;
  const Fy2 = sphere2 + cylinder2 * Math.cos(deg2rad(axis2)) ** 2;
  const Fxy2 = -cylinder2 * Math.sin(deg2rad(axis2)) * Math.cos(deg2rad(axis2));

  return {
    Fx: Fx1 + Fx2,
    Fy: Fy1 + Fy2,
    Fxy: Fxy1 + Fxy2,
  };
}

/********************************************************************
 * Calculate diopters from matrix
 ********************************************************************/
export function calculateMatrix2diopter({ Fx, Fy, Fxy }) {
  const SpF = Fx + Fy;
  const detF = Fx * Fy - Fxy ** 2;
  const cylinder = -Math.sqrt(SpF ** 2 - 4 * detF);
  const sphere = (SpF - cylinder) / 2;
  const axis = Fxy !== 0 ? rad2deg(Math.atan((sphere - Fx) / Fxy)) : 0;

  return {
    sphere,
    cylinder,
    axis,
  };
}

/********************************************************************
 * Calculate the sum of matrices
 ********************************************************************/
export function calculateMatrixSum({ Fx1, Fx2, Fy1, Fy2, Fxy1, Fxy2 }) {
  const Fx = Fx1 + Fx2;
  const Fy = Fy1 + Fy2;
  const Fxy = Fxy1 + Fxy2;

  return calculateMatrix2diopter({ Fx, Fy, Fxy });
}