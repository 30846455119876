import { Dialog } from "primereact/dialog";
import React from "react";

const ResultsModal = ({ visible, setVisible, table1, table2 }) => {
  return (
    <Dialog
      header={"Order parameters"}
      visible={visible}
      onHide={() => setVisible(false)}
      draggable={false}
      className="background-lightgray "
    >
      <div className="flex flex-column gap-4 w-full">
        {table1}
        {table2 && table2}
      </div>
    </Dialog>
  );
};

export default ResultsModal;
