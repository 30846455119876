import * as yup from "yup";
import {
  axisValidation,
  sphereCylinderValidation,
  vertexValidation,
} from "./schemaHelpers";

export const schema = yup
  .object({
    sphereOD: sphereCylinderValidation,
    sphereOS: sphereCylinderValidation,
    cylinderOD: sphereCylinderValidation,
    cylinderOS: sphereCylinderValidation,
    axisOD: axisValidation,
    axisOS: axisValidation,
    vertexOldOD: vertexValidation,
    vertexOldOS: vertexValidation,
    vertexNewOD: vertexValidation,
    vertexNewOS: vertexValidation,
  })
  .test(
    "cylinder-axis-relation",
    "If a cylinder value is provided, the related axis field must be filled, and vice versa.",
    function (value) {
      const { cylinderOD, cylinderOS, axisOD, axisOS } = value || {};

      if (cylinderOD && !axisOD) {
        return this.createError({
          path: "axisOD",
          message: "This value is required",
        });
      }
      if (axisOD && !cylinderOD) {
        return this.createError({
          path: "cylinderOD",
          message: "This value is required",
        });
      }

      if (cylinderOS && !axisOS) {
        return this.createError({
          path: "axisOS",
          message: "This value is required",
        });
      }
      if (axisOS && !cylinderOS) {
        return this.createError({
          path: "cylinderOS",
          message: "This value is required",
        });
      }

      return true;
    }
  );
