import React from "react";
import CardContainer from "../CardContainer";
import InputsContainer from "../InputsContainer";
import { useTranslation } from "react-i18next";
import InputsComponent from "../InputsComponent";
import {
  RGPAirSectionOld1,
  RGPAirSectionOld2,
} from "../../constants/constants";
const RefractionInputs = ({
  isRGPAir,
  control,
  errors,
  sphereCylinderODIndex,
  axisODIndex,
  sphereCylinderOSIndex,
  axisOSIndex,
  sphereCylinderInputs,
  axisInput,
  formTransition,
  onToggle,
  isOpen,
}) => {
  const { t } = useTranslation();

  return (
    <CardContainer title={t("Lens power")} onToggle={onToggle} isOpen={isOpen === t("Lens power")}>
      {!isRGPAir ? (
        <InputsContainer description={t("OD")}>
          <InputsComponent
            inputs={sphereCylinderInputs}
            eye="OD"
            control={control}
            errors={errors}
            className="sphere-cylinder"
            tabOrderIndex={sphereCylinderODIndex}
            formTransition={formTransition}
          />
          <InputsComponent
            inputs={axisInput}
            eye="OD"
            control={control}
            errors={errors}
            className="axis"
            tabOrderIndex={axisODIndex}
            inputMode="numeric"
            formTransition={formTransition}
          />
        </InputsContainer>
      ) : (
        <InputsContainer description={t("OD")} isSection>
          <div className="section-input">
            <InputsComponent
              inputs={RGPAirSectionOld1}
              eye="OD"
              control={control}
              errors={errors}
              className="section-input-w"
              tabOrderIndex={["1"]}
              formTransition={formTransition}
            />
          </div>
          <div className="section-input">
            <InputsComponent
              inputs={RGPAirSectionOld2}
              eye="OD"
              control={control}
              errors={errors}
              className="section-input-w"
              tabOrderIndex={["2"]}
              formTransition={formTransition}
            />
          </div>
        </InputsContainer>
      )}
      {!isRGPAir ? (
        <InputsContainer description={t("OS")}>
          <InputsComponent
            inputs={sphereCylinderInputs}
            eye="OS"
            control={control}
            errors={errors}
            className="sphere-cylinder"
            tabOrderIndex={sphereCylinderOSIndex}
            formTransition={formTransition}
          />
          <InputsComponent
            inputs={axisInput}
            eye="OS"
            control={control}
            errors={errors}
            className="axis"
            tabOrderIndex={axisOSIndex}
            inputMode="numeric"
            formTransition={formTransition}
          />
        </InputsContainer>
      ) : (
        <InputsContainer description={t("OS")} isSection>
          <div className="section-input">
            <InputsComponent
              inputs={RGPAirSectionOld1}
              eye="OS"
              control={control}
              errors={errors}
              className="section-input-w"
              tabOrderIndex={["8"]}
              formTransition={formTransition}
            />
          </div>
          <div className="section-input">
            <InputsComponent
              inputs={RGPAirSectionOld2}
              eye="OS"
              control={control}
              errors={errors}
              className="section-input-w"
              tabOrderIndex={["9"]}
              formTransition={formTransition}
            />
          </div>
        </InputsContainer>
      )}
    </CardContainer>
  );
};

export default RefractionInputs;
