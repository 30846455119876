/********************************************************************
 * List of manufacturers and their products/materials.
 ********************************************************************/
export const manufacturers = [
  {
      manufacturer: "Appenzeller KL",
      categories: ["Contact lenses"],
      countries: ["de", "ch", "at"],
      materials: [
          { name: "Optimum Classic", n: 1.453, dk: 26 },
          { name: "Optimum Comfort", n: 1.441, dk: 65 },
          { name: "Optimum Extra", n: 1.433, dk: 100 },
          { name: "Optimum Extreme", n: 1.432, dk: 125 },
          { name: "Optimum Infinite", n: 1.438, dk: 200 },
          { name: "Boston IV", n: 1.469, dk: 14 },
          { name: "Boston ES", n: 1.443, dk: 14 },
          { name: "Boston Equalens", n: 1.439, dk: 35 },
          { name: "Boston EO", n: 1.429, dk: 44 },
          { name: "Boston XO", n: 1.415, dk: 75 },
          { name: "Visaflex", n: 1.492, dk: 18 },
          { name: "PMMA", n: 1.490, dk: 0 },
          { name: "TLM", n: 1.45, dk: 18 }
      ],
      notation: "RGP"
  },
  {
      manufacturer: "Hecht KL",
      categories: ["Contact lenses"],
      countries: ["de", "ch", "dk"],
      materials: [
          { name: "Boston Equalens", n: 1.439, dk: 18 },
          { name: "Boston ES", n: 1.443, dk: 18 },
          { name: "Boston ES2", n: 1.429, dk: 44 },
          { name: "Boston XO", n: 1.415, dk: 75 },
          { name: "Boston XO2", n: 1.424, dk: 106 },
          { name: "Acuity 200", n: 1.43, dk: 0 },
          { name: "Paragon HDS", n: 1.449, dk: 75 },
          { name: "Optimum Extra", n: 1.433, dk: 100 }
      ],
      notation: "RGPAirSections"
  },
  {
      manufacturer: "MPG&E",
      categories: ["Contact lenses"],
      countries: ["de", "ch", "at"],
      materials: [
          { name: "Optimum Classic", n: 1.453, dk: 26 },
          { name: "Optimum Comfort", n: 1.441, dk: 65 },
          { name: "Optimum Extra", n: 1.433, dk: 100 },
          { name: "Boston ES", n: 1.443, dk: 14 },
          { name: "Boston EO", n: 1.429, dk: 44 },
          { name: "Boston XO", n: 1.415, dk: 75 }
      ],
      notation: "RGP"
  },
  {
      manufacturer: "Menicon",
      categories: ["Contact lenses"],
      countries: ["de", "ch", "at"],
      materials: [
          { name: "Menicon Z", n: 1.44, dk: 122 },
          { name: "Menicon EX", n: 1.438, dk: 39 }
      ],
      notation: "RGP"
  },
  {
      manufacturer: "Schweizer Optik",
      categories: ["Low vision"],
      countries: ["de", "ch", "at"],
      products: [
          { name: "ML Vidi 1.8", magnification: 1.8, fieldOfView: 25 },
          { name: "ML Vidi 1.6", magnification: 1.6, fieldOfView: 36 }
      ]
  },
  {
      manufacturer: "Eschenbach Optik",
      categories: ["Low vision"],
      countries: ["de", "ch", "at"],
      products: [
          { name: "Galilei 2.0 GF", magnification: 2, fieldOfView: 22 },
          { name: "Galilei 2.2x", magnification: 2.2, fieldOfView: 14 },
          { name: "Galilei 2.5x", magnification: 2.2, fieldOfView: 13 }
      ]
  }
];