import React from "react";
import { Dropdown } from "primereact/dropdown";

const Select = ({ onChange, value, options, className, label }) => {
  return (
    <div className="relative flex align-items-center border border-round-md w-max">
      <Dropdown value={value} onChange={onChange} options={options} className={`border-round-md ${className}  flex align-items-center`} />
      {label && <p className="select-label absolute font-medium">{label}</p>}
    </div>
  );
};

export default Select;
