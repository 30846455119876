export const parseFormData = (data, fields) => {
  const calculationResult = {};

  ["OD", "OS"].forEach((eye) => {
    calculationResult[eye] = {};
    fields.forEach((field) => {
      calculationResult[eye][field] = parseFloat(data[`${field}${eye}`]) || 0;
    });
  });

  return calculationResult;
};
