import React from "react";
import { useMediaQuery } from "react-responsive";

const InputsContainer = ({ description, children, classNames, isResult, alowWrap }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 979 });
  // const isMobile = useMediaQuery({ maxWidth: 429 });

  return (
    children && (
      <div
        className={`flex align-items-end ${classNames ? classNames : ""} ${!isResult ? "container-gap" : "res-container-gap"} ${!isTabletOrMobile ? "container-gap" : ""}  ${alowWrap ? "flex-wrap" : ""}`}
        style={{ minWidth: "118px" }}
      >
        {description && (
          <div
            className={`eye-label rounded-md border flex flex-1 align-items-center justify-content-center text-sm ${isResult && "result-item"}`}
          >
            <p>{description}</p>
          </div>
        )}
        {/* Ensure the children take the available space */}
        {children}
      </div>
    )
  );
};

export default InputsContainer;
