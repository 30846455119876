import * as yup from "yup";

const sphereCylinderPattern =
  /^([+-]?(?:[0-9]|[1-9][0-9]?)(?:\.\d*)?)?\s*(dpt)?$/;

const axisPattern =
  /^(?:[0-9]|[1][0-9]|[2][0-9]|[3][0-9]|[4][0-9]|[5][0-9]|[6][0-9]|[7][0-9]|[8][0-9]|90|[9][1-9]|[1][0-7][0-9]|180)?°?$/;

const vertexPattern = /^((?:[0-9]|[1-4][0-9]|50)(?:\.\d*)?)?\s*(mm)?$/;

const radiusPattern = /^(1[0-5]|[1-9])(\.[0-9]{1,2})?$/;

export const rotationPattern =
  /^-?((?:[0-9]|[1-8][0-9]|90|1[0-7][0-9]|180)(?:\.\d+)?|(?:-[1-9]|-?[1-8][0-9]|-90)(?:\.\d+)?)$/;

export const radiusValidation = yup
  .string()
  .matches(radiusPattern, "Invalid number format");

export const sphereCylinderValidation = yup
  .string()
  .matches(sphereCylinderPattern, "Invalid number format");

export const axisValidation = yup
  .string()
  .matches(axisPattern, "Invalid number format");

export const rotationValidation = yup
  .string()
  .matches(rotationPattern, "Invalid number format");

export const vertexValidation = yup
  .string()
  .matches(vertexPattern, "Invalid number format");
