export const de = {
  English: "Englisch",
  German: "Deutsch",
  Language: "Sprache",
  Country: "Land",
  Settings: "Einstellungen",
  AppTitle: "Linsenrechner",
  Sphere: "Sphäre",
  Cylinder: "Zylinder",
  Axis: "Achse",
  "Section 1": "Hauptschnitt 1",
  "Section 2": "Hauptschnitt 2",
  "Vertex old": "HSA (alt)",
  "Vertex new": "HSA (neu)",
  Calculate: "Berechnen",
  Vertex: "HSA",
  "Rotation & Vertex": "Rotation & HSA",
  "Lens power": "Linsenwerte",
  Clear: "Löschen",
  "Cylinder notation": "Zylinderschreibweise",
  Cyl: "Zyl",
  "Diopter rounding": "Rundung Dioptriewerte",
  "Curvature unit": "Radien Einheit",
  "Prism notation": "Prismen Schreibweise",
  Degrees: "Grad",
  Rounding: "Rundung",
  Directions: "Richtung",
  "Prism unit": "Prismen Einheit",
  OD: "R",
  OS: "L",
  "Over-refraction": "Überrefraktion",
  "New parameters": "Neue Parameter",
  "Lens parameters": "Linsenparameter",
  "Contact lens orientation": "Kontaktlinsen Orientierung",
  Rotation: "Rotation",
  "Lens rotation": "Linsen Rotation",
  Stabilisation: "Stabilisierung",
  "Stabilisation (TABO)": "Stabilisierung (TABO)",
  toastInfo: "Bitte geben Sie Werte für die Berechnung ein.",
  menuItems: {
    "Contact lenses": "Kontaktlinsen",
    "Spectacle optics": "Brillenoptik",
    "General optics": "Allgemeine Optik",
    "Low Vision": "Low Vision",
    Myopia: "Myopie",
    RGP: "Formstabile Linsen",
    "Vertex conversion": "HSA Umrechnung",
    "Soft lens": "Weiche Linsen",
    "⁠⁠Combine prism": "Prisma berechnen",
    "Resolve prism": "Prisma zerlegen",
  },
  Out: "Außen",
  In: "Innen",
  Up: "Oben",
  Down: "Unten",
  LensTooltip:"Sie können die Linse drehen, indem Sie darauf klicken",
};
