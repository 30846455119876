import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { StyleClass } from "primereact/styleclass";
import { ReactComponent as LenseIcon } from "../../assets/lense.svg";
import { ReactComponent as GlassesIcon } from "../../assets/glasses.svg";
import { ReactComponent as GearIcon } from "../../assets/gear.svg";
import { ReactComponent as LogoIcon } from "../../assets/logo.svg";
import { ReactComponent as OpticsIcon } from "../../assets/optics.svg";
import { ReactComponent as LowVisionIcon } from "../../assets/lowVision.svg";
import { ReactComponent as EyeIcon } from "../../assets/eye.svg";
import { useTranslation } from "react-i18next";
import SidebarTabs from "./SidebarTabs";
import { useMediaQuery } from "react-responsive";
import BtnGroup from "../BtnGroup";

const Layout = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const showMenuHavbar = useMediaQuery({ maxWidth: 1199 });

  const location = useLocation();

  const { t } = useTranslation();

  const btnRef = useRef();
  const outlet = useRef();

  const tabs = [
    {
      groupName: t("menuItems.Contact lenses"),
      name: "contactLenses",
      icon: <LenseIcon className="mr-2" />,
      menuItems: [
        { name: t("menuItems.RGP"), path: "/rgp" },
        { name: t("menuItems.Soft lens"), path: "/soft-lens" },
      ],
    },
    {
      groupName: t("menuItems.Spectacle optics"),
      name: "Spectacle optics",
      icon: <GlassesIcon className="mr-2" />,
      menuItems: [
        { name: t("menuItems.Vertex conversion"), path: "/vertex" },
        { name: t("menuItems.⁠⁠Combine prism"), path: "/combine-prism" },
        { name: t("menuItems.Resolve prism"), path: "/resolve-prism" },
      ],
    },
    {
      groupName: t("menuItems.General optics"),
      name: "General optics",
      icon: <OpticsIcon className="mr-2" />,
      menuItems: [
        { name: t("menuItems.Vertex conversion"), path: "/vertex" },
        { name: t("menuItems.⁠⁠Combine prism"), path: "/combine-prism" },
        { name: t("menuItems.Resolve prism"), path: "/resolve-prism" },
      ],
    },
    {
      groupName: t("menuItems.Low Vision"),
      name: "Low Vision",
      icon: <LowVisionIcon className="mr-2" />,
      menuItems: [
        { name: t("menuItems.Vertex conversion"), path: "/vertex" },
        { name: t("menuItems.⁠⁠Combine prism"), path: "/combine-prism" },
        { name: t("menuItems.Resolve prism"), path: "/resolve-prism" },
      ],
    },
    {
      groupName: t("menuItems.Myopia"),
      name: "Myopia",
      icon: <EyeIcon className="mr-2" />,
      menuItems: [
        { name: t("menuItems.Vertex conversion"), path: "/vertex" },
        { name: t("menuItems.⁠⁠Combine prism"), path: "/combine-prism" },
        { name: t("menuItems.Resolve prism"), path: "/resolve-prism" },
      ],
    },
  ];

  useEffect(() => {
    if (!outlet.current) return;
    outlet.current.classList.add("hidden");
    setTimeout(() => {
      outlet.current.classList.remove("hidden");
      outlet.current.classList.add("transition");
    }, 100);
  }, [location.pathname]);

  const handleMenuClick = useCallback((menuId) => {
    setOpenMenu((prevOpenMenu) => (prevOpenMenu === menuId ? null : menuId));
  }, []);

  const isActive = (path) => location.pathname === path;

  const closeSidebar = () => {
    const isSmallScreen = window.matchMedia("(max-width: 1199px)").matches;

    if (btnRef.current && isSmallScreen) {
      btnRef.current.click();
    }
  };

  return (
    <div className="custom-layout flex relative lg:static ">
      <div
        id="app-sidebar-1"
        className="sidebar sidebar-background sidebar-hidden"
        style={{ width: "280px"}}
      >
        <div className="flex flex-column">
          <div
            className="flex align-items-center px-4 sidebar-background  flex-shrink-0"
            style={{ height: "60px" }}
          >
            <span className="flex gap-2">
              <LogoIcon />
              <p className="text-xl text-bluegray-100">
                {t("AppTitle")}
              </p>
            </span>
          </div>
          <div className="overflow-y-auto p-3 mt-3">
            <SidebarTabs
              openMenu={openMenu}
              handleMenuClick={handleMenuClick}
              closeSidebar={closeSidebar}
              tabs={tabs}
            ></SidebarTabs>
          </div>
          <div className="fixed bottom-0  mx-3 w-15rem ">
            <div className="sidebar-divider" />
            <Link
              to="/settings"
              className={`menu-item my-3 flex align-items-center border-round cursor-pointer text-bluegray-100 transition-duration-150 transition-colors w-full no-underline ${
                isActive("/settings")
                  ? "active-background text-bluegray-50"
                  : "sidebar-item hover:text-bluegray-50"
              }`}
              onClick={closeSidebar}
            >
              <span className="font-medium flex align-items-center ">
                <GearIcon className="mr-2" />
                {t("Settings")}
              </span>
            </Link>
          </div>
        </div>
      </div>

      <div className="min-h-screen flex flex-column relative flex-auto background-lightgray">
        <StyleClass
          nodeRef={btnRef}
          selector="#app-sidebar-1"
          enterClassName="sidebar-hidden"
          enterActiveClassName="ease-in"
          leaveToClassName="sidebar-hidden"
          leaveActiveClassName="ease-out"
          hideOnOutsideClick
        >
          <div
            ref={btnRef}
            className="cursor-pointer burger-menu text-700 w-max pb-0 z-2 pt-4"
          >
            <i className="far fa-bars"></i>
          </div>
        </StyleClass>
        <div ref={outlet} className="flex-grow-1 outlet-container">
          <Outlet />
        </div>
      </div>
      {showMenuHavbar && (
        <div className="custom-header">
          <BtnGroup hideCalculationBtns={true} />
        </div>
      )}
    </div>
  );
};

export default Layout;
