import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import { HashRouter, useLocation } from "react-router-dom";
import { PrimeReactProvider } from "primereact/api";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./i18n/i18n.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

const withSwRegistration = (WrappedComp) => {
  return () => {
    // holds all the SW registration setup
    const [appUpdatePending, setAppUpdatePending] = useState(false);
    const location = useLocation();
    // updates the state when a new update is pending.
    const onSWUpdate = (registration) => {
      // console.log("Service Worker update detected.");
      setAppUpdatePending(true);
      if (registration.waiting) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
        registration.waiting.addEventListener("statechange", (event) => {
          const { state = "" } = event.target || {};
          if (state === "activated") {
            // console.log("New service worker activated.");
            window.location.reload();
          }
        });
      }
    };

    // action for updating the service worker.
    const updateAction = () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          if (registration.waiting) {
            // send the skip message to kick off the service worker install.
            registration.waiting.postMessage({ type: "SKIP_WAITING" });
            // add an listener to reload page when the new service worker is ready.
            registration.waiting.addEventListener("statechange", (event) => {
              const { state = "" } = event.target || {};
              if (state === "activated") {
                window.location.reload();
              }
            });
          }
        });
      }
    };
    // effect added from router location to check for a new service worker on every
    // page transition (change of route).
    useEffect(() => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.update();
        });
      }
    }, [location]);

    // registers the service worker based on config setting.
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
    //console.log("Service Worker registration initiated.");
    // if (window.REACT_APP_REGISTER_SW === true) {
    // } else {
    //   console.log(
    //     "LocalStorage updated value:",
    //     localStorage.getItem("updated")
    //   );
    //   serviceWorkerRegistration.unregister();
    //   console.log("Service Worker unregistration initiated.");
    // }

    return (
      <WrappedComp
        updateAction={updateAction}
        appUpdatePending={appUpdatePending}
      />
    );
  };
};

const AppWithSwRegistration = withSwRegistration(App);

const MainApp = () => {
  return (
    <HashRouter>
      <PrimeReactProvider value={{ ripple: true }}>
        <AppWithSwRegistration />
      </PrimeReactProvider>
    </HashRouter>
  );
};
serviceWorkerRegistration.unregister();

root.render(<MainApp />);
