import React from "react";
import { Card } from "primereact/card";
import { useMediaQuery } from "react-responsive";

const CardContainer = ({ title, children, className, isCombinePrism, eye, onToggle }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 899 });


  return !isTabletOrMobile ? (
    !isCombinePrism ? (
      <Card title={title}>
        <div className={`flex flex-column gap-3 flex-1 ${className}`}>{children}</div>
      </Card>
    ) : (
      <Card className="prism-card">
        <div className="flex gap-2">
          <div className="flex border rounded-[8px] align-items-center justify-content-center eye-label text-sm">{eye}</div>
          <div className={`flex flex-column gap-3 flex-1 ${className}`}>{children}</div>
        </div>
      </Card>
    )
  ) : (
    <div className="relative" onClick={() => onToggle(title)}>
      {/* <Panel
        header={title}
        toggleable
        collapsed={!isOpen}
        onToggle={() => onToggle(title)}
        style={{
          borderRadius: "6px",
          overflow: "hidden",
        }}
        className={disableToggle ? "hide-panel-header" : ""}
      >
        <div
          ref={contentRef}
          className={`flex flex-column gap-3 flex-1 ${className} ${
            isOpen ? "slide-down" : "slide-up"
          }`}
          style={{
            transition: "max-height 0.5s ease",
            maxHeight: maxHeight,
            overflow: isAnimating ? "hidden" : "visible",
            paddingTop: "10px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {disableToggle ? (
            <div className="flex gap-2">
              <div className="flex align-items-center justify-content-center eye-label text-sm border border-solid border-round-md">
                {eye}
              </div>
              <div className={`flex flex-column gap-3 flex-1 ${className}`}>
                {children}
              </div>
            </div>
          ) : (
            <>{children}</>
          )}
        </div>
      </Panel>
      <div
        className="absolute cursor-pointer panel-arrow"
        onClick={(e) => {
          e.stopPropagation();
          onToggle(title);
        }}
        style={{ display: disableToggle ? "none" : "block" }}
      >
        <ArrowIcon />
      </div> */}
      <Card title={title}>
        <div className={`flex flex-column gap-3 flex-1 ${className}`}>{children}</div>
      </Card>
    </div>
  );
};

export default CardContainer;
