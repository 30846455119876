import * as yup from "yup";
import {
  axisValidation,
  radiusValidation,
  rotationValidation,
  sphereCylinderValidation,
  vertexValidation,
} from "./schemaHelpers";

export const schema = yup
  .object({
    section1OD: sphereCylinderValidation,
    section2OD: sphereCylinderValidation,
    section1OS: sphereCylinderValidation,
    section2OS: sphereCylinderValidation,
    radiusOld1OD: radiusValidation,
    radiusOld1OS: radiusValidation,
    radiusNew1OD: radiusValidation,
    radiusNew1OS: radiusValidation,
    radiusOld2OD: radiusValidation,
    radiusOld2OS: radiusValidation,
    radiusNew2OD: radiusValidation,
    radiusNew2OS: radiusValidation,
    lensPowerSphereOD: sphereCylinderValidation,
    lensPowerSphereOS: sphereCylinderValidation,
    lensPowerCylinderOD: sphereCylinderValidation,
    lensPowerCylinderOS: sphereCylinderValidation,
    lensPowerAxisOD: axisValidation,
    lensPowerAxisOS: axisValidation,
    overRefractionSphereOD: sphereCylinderValidation,
    overRefractionSphereOS: sphereCylinderValidation,
    overRefractionCylinderOD: sphereCylinderValidation,
    overRefractionCylinderOS: sphereCylinderValidation,
    overRefractionAxisOD: axisValidation,
    overRefractionAxisOS: axisValidation,
    rotationOD: rotationValidation,
    rotationOS: rotationValidation,
    vertexOD: vertexValidation,
    vertexOS: vertexValidation,
  })
  .test(
    "Radius must be provided",
    "At least one of radiusOld1OD or radiusOld2OD or one of radiusOld1OS or radiusOld2OS must be provided",
    function (value) {
      const {
        radiusOld1OD,
        radiusOld2OD,
        radiusOld1OS,
        radiusOld2OS,
        lensPowerSphereOD,
        lensPowerCylinderOD,
        lensPowerAxisOD,
        lensPowerSphereOS,
        lensPowerCylinderOS,
        lensPowerAxisOS,
        sectionOld1OD,
        sectionOld2OD,
        sectionOld1OS,
        sectionOld2OS,
      } = value || {};

      const isODFilled =
        lensPowerSphereOD ||
        lensPowerCylinderOD ||
        lensPowerAxisOD ||
        sectionOld1OD ||
        sectionOld2OD;
      const isOSFilled =
        lensPowerSphereOS ||
        lensPowerCylinderOS ||
        lensPowerAxisOS ||
        sectionOld1OS ||
        sectionOld2OS;

      const isRadiusODValid = radiusOld1OD || radiusOld2OD;
      const isRadiusOSValid = radiusOld1OS || radiusOld2OS;

      if (isODFilled && !isRadiusODValid) {
        return this.createError({
          path: "radiusOld1OD",
          message: "At least one radius for OD must be provided",
        });
      }

      if (isOSFilled && !isRadiusOSValid) {
        return this.createError({
          path: "radiusOld1OS",
          message: "At least one radius for OS must be provided",
        });
      }

      return true;
    }
  )
  .test(
    "cylinder-axis-relation",
    "If a cylinder value is provided, the related axis field must be filled, and vice versa.",
    function (value) {
      const {
        lensPowerCylinderOD,
        lensPowerAxisOD,
        lensPowerCylinderOS,
        lensPowerAxisOS,
        overRefractionCylinderOD,
        overRefractionCylinderOS,
        overRefractionAxisOD,
        overRefractionAxisOS,
      } = value || {};

      const validatePair = (cylinder, axis, cylinderPath, axisPath) => {
        if (cylinder && !axis) {
          return this.createError({
            path: axisPath,
            message: "This value is required",
          });
        }
        if (axis && !cylinder) {
          return this.createError({
            path: cylinderPath,
            message: "This value is required",
          });
        }
        return true;
      };

      // Validate lensPower pairs
      let result = validatePair(
        lensPowerCylinderOD,
        lensPowerAxisOD,
        "lensPowerCylinderOD",
        "lensPowerAxisOD"
      );
      if (result !== true) return result;

      result = validatePair(
        lensPowerCylinderOS,
        lensPowerAxisOS,
        "lensPowerCylinderOS",
        "lensPowerAxisOS"
      );
      if (result !== true) return result;

      // Validate overRefraction pairs
      result = validatePair(
        overRefractionCylinderOD,
        overRefractionAxisOD,
        "overRefractionCylinderOD",
        "overRefractionAxisOD"
      );
      if (result !== true) return result;

      result = validatePair(
        overRefractionCylinderOS,
        overRefractionAxisOS,
        "overRefractionCylinderOS",
        "overRefractionAxisOS"
      );
      if (result !== true) return result;

      return true;
    }
  );
