import React from "react";
import InputsContainer from "./InputsContainer.jsx";
import { Card } from "primereact/card";
import { useTranslation } from "react-i18next";
// import { useMediaQuery } from "react-responsive";

const ResultTable = ({
  values,
  createResultItems,
  isFirst,
  isSecondTable,
  isSkipped,
  width,
  classNames
}) => {
  const { t } = useTranslation();
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 979 });

  return (
    !isSkipped && (
      <div
        className={`flex flex-column result-table gap-3 ${classNames}`}
        // ${!isTabletOrMobile ? "w-max" : ""}
        style={{ width: `${width ? `${width}px` : ""}` }}
      >
        <Card>
          <div className="flex flex-column relative gap-3">
            {values.map((valueSet, index) => {
              const results = createResultItems(
                valueSet,
                isFirst ? isFirst : index === 0
              );
              return (
                <InputsContainer
                  key={`${valueSet}-${index}`}
                  description={isSecondTable || !results ? "" : valueSet.eye === "OD" ? t("OD") : t("OS")}
                  isResult={true}
                >
                  {results}
                </InputsContainer>
              );
            })}
          </div>
        </Card>
      </div>
    )
  );
};

export default ResultTable;
