import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { prism } from "../../package/index.js";
import { AppContext } from "../../App.jsx";
import { parseFormData } from "../../helpers/vertexHelpers.js";
import { useMediaQuery } from "react-responsive";
import { resolveBaseInput, resolvePrismInput } from "../../constants/constants.js";
import { notify } from "../../helpers/toast";
import BtnGroup from "../BtnGroup.jsx";
import ResultTable from "../ResultTable.jsx";
import ResultItem from "../ResultItem.jsx";
import ResultsModal from "../ResultsModal.jsx";
import InputsComponent from "../InputsComponent.jsx";
import CardContainer from "../CardContainer.jsx";
import InputsContainer from "../InputsContainer.jsx";

const ResolvePrismCalculator = () => {
  const [isCleared, setIsCleared] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const [transition, setTransition] = useState("");
  const [resultP1, setResultP1] = useState([]);
  const [resultP2, setResultP2] = useState([]);
  const [visibleResults, setVisibleResults] = useState(false);
  const [resultTransition, setResultTransition] = useState("");
  const [isFirstSubmit, setIsFirstSubmit] = useState(true);
  const [previousFraction, setPreviousFraction] = useState(null);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 979 });
  const showMenuHavbar = useMediaQuery({ maxWidth: 1199 });

  const { fraction, cylinderNotation } = useContext(AppContext);
  const { t } = useTranslation();

  const formRef = useRef(null);

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const handleCalculations = useCallback(
    (data) => {
      const fields = ["baseOD", "baseOS", "prismOD", "prismOS"];

      const { OD, OS } = parseFormData(data, fields);
      const resultOD = prism.resolvePrism({
        prism: OD.prismOD,
        base: OD.baseOD,
        fraction: fraction.value,
        eye: "OD",
      });
      const resultOS = prism.resolvePrism({
        prism: OS.prismOD,
        base: OS.baseOD,
        fraction: fraction.value,
        eye: "OS",
      });

      setResultP1([
        {
          baseDegree: resultOD.base1Degree,
          prism: resultOD.prism1,
          direction: resultOD.base1Direction,
        },
        {
          baseDegree: resultOS.base2Degree,
          prism: resultOS.prism2,
          direction: resultOD.base2Direction,
        },
      ]);

      setResultP2([
        {
          baseDegree: resultOD.base1Degree,
          prism: resultOD.prism1,
          direction: resultOS.base1Direction,
        },
        {
          baseDegree: resultOS.base2Degree,
          prism: resultOS.prism2,
          direction: resultOS.base2Direction,
        },
      ]);
    },
    [fraction]
  );

  useEffect(() => {
    if (!isCalculated) return;

    const formData = getValues();
    handleCalculations(formData);
  }, [fraction.value, getValues, handleCalculations, isCalculated]);

  useEffect(() => {
    if (previousFraction && previousFraction !== fraction) {
      setResultTransition("");
      setTimeout(() => {
        setResultTransition("ripple");
      }, 200);
    }
    setPreviousFraction(fraction);
  }, [cylinderNotation, fraction, previousFraction]);

  useEffect(() => {
    if (isCleared) {
      setTransition("transition-out");
      setIsCleared(false);
    }
    setTimeout(() => {
      setTransition("hidden");
    }, 200);
  }, [isCleared]);

  const onSubmit = (data) => {
    if (!data.baseOD && !data.baseOS && !data.prismOD && !data.prismOS) {
      notify(t("toastInfo"));
      setTimeout(() => {
        const firstInput = document.querySelector('[tabIndex="1"]');
        if (firstInput) {
          firstInput.focus();
        }
      }, 100);
      return;
    }

    setIsCalculated(true);
    handleCalculations(data);
    setVisibleResults(true);

    if (isFirstSubmit) {
      setTransition("hidden");
      setTransition("transition");
      setIsFirstSubmit(false);
    } else {
      setTransition("");
      setResultTransition("");
      setTimeout(() => {
        setResultTransition("ripple");
      }, 200);
    }
  };

  const createResultItem1 = (valueSet, isFirst) => {
    // if (valueSet.prism === 0) {
    //   return null;
    // }
    return (
      <>
        <ResultItem
          resultTransition={resultTransition}
          value={valueSet.baseDegree}
          label="Prism"
          endAdornment="cm/m"
          isFirst={isFirst}
          resultItemWidth="w-[100px]"
        />
        <ResultItem
          resultTransition={resultTransition}
          value={valueSet.prism}
          label="Base"
          endAdornment="°"
          isFirst={isFirst}
          resultItemWidth="w-[80px]"
          direction={valueSet.direction}
        />
      </>
    );
  };

  const createResultItem2 = (valueSet, isFirst) => {
    // if (valueSet.prism === 0) {
    //   return null;
    // }
    return (
      <>
        <ResultItem
          resultTransition={resultTransition}
          value={valueSet.baseDegree}
          label="Prism"
          endAdornment="cm/m"
          isFirst={isFirst}
          resultItemWidth="w-[130px]"
        />
        <ResultItem
          resultTransition={resultTransition}
          value={valueSet.prism}
          label="Base"
          endAdornment="°"
          isFirst={isFirst}
          direction={valueSet.direction}
          resultItemWidth="w-[100px]"
        />
      </>
    );
  };

  return (
    <div className="general-container flex flex-column gap-5 w-max">
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className="flex flex-column justify-content-center gap-5  ">
        <div className="cards-container flex flex-column gap-2">
          <div className="flex flex-column lg:flex-row gap-2">
            <CardContainer eye={t("OD")} isOpen={true} isCombinePrism={true} disableToggle={true}>
              <InputsContainer>
                <InputsComponent
                  inputs={resolvePrismInput}
                  eye="OD"
                  control={control}
                  errors={errors}
                  className="vertex-inp"
                  tabOrderIndex={["1"]}
                  inputMode="decimal"
                ></InputsComponent>
                <InputsComponent
                  inputs={resolveBaseInput}
                  eye="OD"
                  control={control}
                  errors={errors}
                  className="vertex-inp"
                  tabOrderIndex={["2"]}
                  inputMode="numeric"
                ></InputsComponent>
              </InputsContainer>
            </CardContainer>
            <CardContainer eye={t("OS")} isOpen={true} isCombinePrism={true} disableToggle={true}>
              <InputsContainer>
                <InputsComponent
                  inputs={resolvePrismInput}
                  eye="OS"
                  control={control}
                  errors={errors}
                  className="vertex-inp"
                  tabOrderIndex={["3"]}
                  inputMode="decimal"
                ></InputsComponent>
                <InputsComponent
                  inputs={resolveBaseInput}
                  eye="OS"
                  control={control}
                  errors={errors}
                  className="vertex-inp"
                  tabOrderIndex={["4"]}
                  inputMode="numeric"
                ></InputsComponent>
              </InputsContainer>
            </CardContainer>
          </div>
        </div>
        <BtnGroup reset={reset} setIsCleared={setIsCleared} hideSetupBtns={showMenuHavbar}></BtnGroup>
      </form>
      {isCalculated && !isTabletOrMobile && (
        <div className="relative">
          <div className={`flex gap-2 ${transition}`}>
            <ResultTable values={resultP1} createResultItems={createResultItem1} />
            <ResultTable values={resultP2} createResultItems={createResultItem2} isSecondTable={true} />
          </div>
        </div>
      )}
      {isTabletOrMobile && (
        <ResultsModal
          visible={visibleResults}
          setVisible={setVisibleResults}
          table1={<ResultTable values={resultP1} createResultItems={createResultItem1} />}
          table2={<ResultTable values={resultP2} createResultItems={createResultItem2} />}
        ></ResultsModal>
      )}
    </div>
  );
};

export default ResolvePrismCalculator;
