import React from "react";
import { stabilisation } from "../constants/constants";
import { Controller } from "react-hook-form";
import StyledInput from "./StyledInput";
import { getFormattedValue } from "../helpers/inputHelpers";

const InputsComponent = ({
  inputs,
  eye,
  control,
  errors,
  className,
  getValues,
  setValue,
  tabOrderIndex,
  inputMode,
  orientation = stabilisation,
  isRadiusNew1ODChanged,
  isRadiusNew2ODChanged,
  isRadiusNew1OSChanged,
  isRadiusNew2OSChanged,
  setIsRadiusNew1ODChanged,
  setIsRadiusNew2ODChanged,
  setIsRadiusNew1OSChanged,
  setIsRadiusNew2OSChanged,
  formTransition,
  combinePrismPart,
  select,
}) => {
  return inputs.map((input, index) => {
    const inputName = `${input.name}${eye}`;
    return (
      <div className={`${formTransition}`} key={`${inputName}-${index}`}>
        <Controller
          name={inputName}
          control={control}
          render={({ field }) => (
            <StyledInput
              {...field}
              className={className}
              placeholder={
                combinePrismPart
                  ? `${input.placeholder} ${combinePrismPart === 1 ? "1" : "2"}`
                  : input.placeholder
              }
              error={errors[inputName]?.message}
              inputMode={inputMode}
              select={select}
              control={control}
              selectName={`${inputs[0].select}${eye}`}
              onBlur={(e) => {
                const formattedValue = getFormattedValue(
                  inputName,
                  e.target.value,
                  true,
                  getValues,
                  setValue,
                  orientation,
                  isRadiusNew1ODChanged,
                  isRadiusNew2ODChanged,
                  isRadiusNew1OSChanged,
                  isRadiusNew2OSChanged,
                  setIsRadiusNew1ODChanged,
                  setIsRadiusNew2ODChanged,
                  setIsRadiusNew1OSChanged,
                  setIsRadiusNew2OSChanged
                );
                field.onChange(formattedValue ? formattedValue.trim() : "");
              }}
              onChange={(e) =>
                field.onChange(
                  getFormattedValue(
                    inputName,
                    e.target.value,
                    false,
                    getValues,
                    setValue,
                    orientation,
                    isRadiusNew1ODChanged,
                    isRadiusNew2ODChanged,
                    isRadiusNew1OSChanged,
                    isRadiusNew2OSChanged,
                    setIsRadiusNew1ODChanged,
                    setIsRadiusNew2ODChanged,
                    setIsRadiusNew1OSChanged,
                    setIsRadiusNew2OSChanged
                  )
                )
              }
              endAdornment={input.endAdornment}
              tabOrderIndex={tabOrderIndex[index]}
            />
          )}
        />
      </div>
    );
  });
};

export default InputsComponent;
