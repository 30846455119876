import React from "react";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";

const SidebarTabs = ({ closeSidebar, tabs, openMenu, handleMenuClick }) => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  return tabs.map((group, index) => (
    <ul className="list-none  m-0 p-0" key={`${group.name}-${index}`}>
      <li>
        <div
          onClick={() => handleMenuClick(group.name)}
          className="mb-2 menu-item flex align-items-center justify-content-between text-600 cursor-pointer sidebar-item border-round text-bluegray-100 hover:text-bluegray-50 transition-duration-150 transition-colors w-full"
        >
          <span className="flex align-items-center">
            {group.icon}
            {group.groupName}
          </span>
          <i className="far fa-chevron-down" style={{ color: "#e0e4ea" }}></i>
        </div>
        <motion.ul
          className="list-none pr-0 m-0 overflow-hidden"
          style={{ paddingLeft: "26px" }}
          initial={{ height: 0 }}
          animate={{
            height: openMenu === group.name ? "auto" : 0,
          }}
          transition={{ duration: 0.3 }}
        >
          {group.menuItems.map((item, index) => (
            <li className="mb-2" key={`${item.name}-${index}`}>
              <Link
                to={item.path}
                className={`menu-item flex align-items-center border-round cursor-pointer text-bluegray-100 transition-duration-150 transition-colors w-full no-underline ${
                  isActive(item.path !== "/other" && item.path)
                    ? "active-background text-bluegray-50"
                    : "sidebar-item hover:text-bluegray-50"
                }`}
                onClick={closeSidebar}
              >
                <span>{item.name}</span>
              </Link>
            </li>
          ))}
        </motion.ul>
      </li>
    </ul>
  ));
};

export default SidebarTabs;
