import { checkAxis, checkPositive } from "../helpers/check.js";
import { defaultFraction } from "../helpers/constant.js";
import { deg2rad, rad2deg, convertDirectionToDegree, convertDegreeToDirection, convertBaseForOD, convertBaseForOS  } from "../helpers/convert.js";
import { formatValue } from "../helpers/format.js";
import { calculateObliqueAxis } from "./diopter.js";


/********************************************************************
 * Function to combine two prisms
 ********************************************************************/
export function combinePrism({
  prism1,
  base1,
  prism2,
  base2,
  fraction = defaultFraction,
  eye
}) {
  if (!eye) {
    throw new Error("The 'eye' parameter is required and must be either 'OD' or 'OS'.");
  }

  // Validate inputs
  prism1 = checkPositive(prism1);
  base1 = convertDirectionToDegree(base1, eye);
  prism2 = checkPositive(prism2);
  base2 = convertDirectionToDegree(base2, eye);

  // Calculate x/y components for both prisms
  const x = prism1 * Math.cos(deg2rad(base1)) + prism2 * Math.cos(deg2rad(base2));
  const y = prism1 * Math.sin(deg2rad(base1)) + prism2 * Math.sin(deg2rad(base2));

  // Calculate resulting prism strength and base
  const prism = Math.sqrt(x * x + y * y);
  let base = rad2deg(Math.atan2(y, x));
  if (base < 0) base += 360;

  // Adjust base direction based on eye
  if (eye === "OD") {
    base = convertBaseForOD(base, x, y);
  } else if (eye === "OS") {
    base = convertBaseForOS(base, x, y);
  }

  //console.log("Calculated base before rounding:", base);

    return {
      prism,
      base,
      formattedPrism:  formatValue (prism, fraction),
      formattedBase: Math.round(base)
    };

}
/********************************************************************
 * Function to resolve a prism into two component prisms (simplified)
 ********************************************************************/
export function resolvePrism({
  prism,
  base,
  fraction = defaultFraction,
  eye
}) {
  // Validate inputs
  prism = checkPositive(prism);
  base = convertDirectionToDegree(base, eye);

  // Calculate x/y components
  const x = prism * Math.cos(deg2rad(base));
  const y = prism * Math.sin(deg2rad(base));

  // Debugging: Überprüfe x und y vor der Übergabe an formatValue
  //console.log("x:", x, "y:", y);

  // Determine component prisms and their bases
  const prism1 = Math.abs(x);
  const prism2 = Math.abs(y);
  const base1 = x >= 0 ? 0 : 180;
  const base2 = y >= 0 ? 90 : 270;

  // Debugging: Überprüfe prism1 und prism2 vor der Formatierung
  console.log("prism1:", prism1, "prism2:", prism2);

    return {
      prism1,  
      base1,                  
      prism2,  
      base2,  

      formattedPrism1: formatValue(prism1, fraction),
      base1Degree: Math.round(base1),
      base1Direction: convertDegreeToDirection(base1, eye),

      formattedPrism2: formatValue(prism2, fraction),
      base2Degree: Math.round(base2),
      base2Direction: convertDegreeToDirection(base2, eye),
    };
  }

/********************************************************************
 * Function to calculate decentered prism effect
 ********************************************************************/
export function decenterPrism({
  sphere = 0,
  cylinder = 0,
  axis = 0,
  vertex = 0,
  decentration = 0,
  axisDecentration = 0,
  fraction = defaultFraction,
}) {
  // Check and validate inputs
  axis = checkAxis(axis);
  vertex = checkPositive(vertex);
  decentration = checkPositive(decentration);
  axisDecentration = checkAxis(axisDecentration);

  // Calculate the effective diopter using oblique axis
  const { sphere: obliqueSphere, cylinder: obliqueCylinder } = calculateObliqueAxis({
    sphere,
    cylinder,
    axis,
    axisNew: axisDecentration,
  });

  const diopter = obliqueSphere + obliqueCylinder;

  // Simplified Weinhold's formula for prismatic effect
  const prism = (decentration * diopter) / (10 - (13.5 + vertex) * diopter / 1000);

  // Format the prism value using formatValue
  return formatValue(prism, fraction);
}

/********************************************************************
 * Function to calculate convergence
 ********************************************************************/
export function calculateConvergence({
  distance,
  pd,
  fraction = defaultFraction,
}) {
  distance = checkPositive(distance);
  pd = checkPositive(pd);

  const convergence = (10 / distance) * pd;

  return formatValue(convergence, fraction);
}