import { Dialog } from "primereact/dialog";
import React from "react";
import { useTranslation } from "react-i18next";

const TipModal = ({ visible, setVisible }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      header={t("menuItems.RGP")}
      visible={visible}
      style={{ maxWidth: "390px" }}
      onHide={() => setVisible(false)}
      draggable={false}
    >
      <p className="text-sm">
        This tool refines your RGP contact lens prescription by calculating the
        over-refractionand stabilisation on the eye.
      </p>
    </Dialog>
  );
};

export default TipModal;
