import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
  useMemo,
} from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ReactComponent as Lens } from "../../assets/rotationLensLg.svg";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import { stabilisation } from "../../constants/constants";
import { formatRotatingLensInput } from "../../helpers/inputHelpers";
import { calculateRotation } from "../../helpers/rgpHelpers";
import gsap from "gsap";
import { Draggable } from "gsap/Draggable";
import { useMediaQuery } from "react-responsive";
import { Tooltip } from "primereact/tooltip";

const RotationModal = ({
  visible,
  setVisible,
  setValue,
  rotateOD,
  rotateOS,
}) => {
  const [rotationLens1Inp, setRotationLens1Inp] = useState("");
  const [rotationLens2Inp, setRotationLens2Inp] = useState("");
  const [rotationLens1, setRotationLens1] = useState(0);
  const [rotationLens2, setRotationLens2] = useState(0);
  const [focusState, setFocusState] = useState({ 1: false, 2: false });
  const [hoverState, setHoverState] = useState({ 1: false, 2: false });
  const [isDragging, setIsDragging] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 599 });


  const { t } = useTranslation();

  const { lensOrientation } = useContext(AppContext);

  const lensRef1 = useRef();
  const lensRef2 = useRef();

  useEffect(() => {
    rotateOD && setRotationLens1(calculateRotation(rotateOD));
    setRotationLens1Inp(rotateOD);
    rotateOS && setRotationLens2(calculateRotation(rotateOS));
    setRotationLens2Inp(rotateOS);
  }, [rotateOD, rotateOS]);

  const lenses = useMemo(
    () => [
      {
        lens: 1,
        label: "OD",
        rotation: rotationLens1,
        rotationInp: rotationLens1Inp,
        setRotationInp: setRotationLens1Inp,
        lensRef: lensRef1,
      },
      {
        lens: 2,
        label: "OS",
        rotation: rotationLens2,
        rotationInp: rotationLens2Inp,
        setRotationInp: setRotationLens2Inp,
        lensRef: lensRef2,
      },
    ],
    [rotationLens1, rotationLens1Inp, rotationLens2, rotationLens2Inp]
  );

  const clampAngle = useCallback(
    (angle) => Math.max(-90, Math.min(90, angle)),
    []
  );

  useEffect(() => {
    if (!visible) return;
    gsap.registerPlugin(Draggable);

    const initializeDraggable = () => {
      const rotationOffset = 90,
        RAD2DEG = 180 / Math.PI;
      lenses.forEach((lens) => {
        gsap.set(lens.lensRef.current, {
          transformOrigin: "center center",
          rotation: lens.lens === 1 ? rotationLens1 : rotationLens2,
        });
        Draggable.create(lens.lensRef.current, {
          type: "rotation",
          cursor: "nwse-resize",
          bounds: { minRotation: -90, maxRotation: 90 },
          onClick: function (e) {
            setIsDragging(false);
            if (!this.rotationOrigin || isDragging) {
              setIsDragging(false);

              return;
            }
            let rotation =
              Math.atan2(
                this.pointerY - this.rotationOrigin.y,
                this.pointerX - this.rotationOrigin.x
              ) *
                RAD2DEG +
              rotationOffset +
              180;
            if (rotation > 180) {
              rotation -= 360;
            } else if (rotation < -180) {
              rotation += 360;
            }
            rotation = clampAngle(rotation);
            rotation = rotation.toFixed();
            const displayAngle =
              rotation >= 0 ? 180 - Number(rotation) : -rotation;

            gsap.to(lens.lensRef.current, {
              duration: 0.3,
              rotation: rotation,
              ease: "power2.inOut",
            });

            if (lens.lens === 1) {
              setRotationLens1(rotation);
              setRotationLens1Inp(
                lensOrientation === stabilisation ? displayAngle : -rotation
              );
            } else {
              setRotationLens2(rotation);
              setRotationLens2Inp(
                lensOrientation === stabilisation ? displayAngle : -rotation
              );
            }
          },
          onDrag: function () {
            setIsDragging(true);
            let rotation = this.rotation.toFixed();
            rotation = clampAngle(rotation);
            const displayAngle =
              rotation >= 0 ? 180 - Number(rotation) : -rotation;

            if (lens.lens === 1) {
              setRotationLens1(rotation);
              setRotationLens1Inp(
                lensOrientation === stabilisation ? displayAngle : -rotation
              );
            } else {
              setRotationLens2(rotation);
              setRotationLens2Inp(
                lensOrientation === stabilisation ? displayAngle : -rotation
              );
            }
          },
        });
      });
    };

    const timeoutId = setTimeout(() => {
      initializeDraggable();
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [
    clampAngle,
    isDragging,
    lensOrientation,
    lenses,
    rotationLens1,
    rotationLens2,
    visible,
  ]);

  const onApplyClick = useCallback(() => {
    setValue("rotationOD", rotationLens1Inp);
    setValue("rotationOS", rotationLens2Inp);
    setVisible(false);
  }, [setValue, rotationLens1Inp, rotationLens2Inp, setVisible]);

  const onHide = useCallback(() => {
    setVisible(false);
    setRotationLens1(rotateOD);
    setRotationLens2(rotateOS);
    setRotationLens1Inp(rotateOD);
    setRotationLens2Inp(rotateOS);
  }, [rotateOD, rotateOS, setVisible]);

  const handleInputChange = useCallback((e, lens) => {
    const inputValue = formatRotatingLensInput(e.target.value);
    const value = Number(e.target.value);

    if (value > 180) {
      if (lens === 1) {
        setRotationLens1Inp("180");
        setRotationLens1(0);
        return;
      } else {
        setRotationLens2Inp("180");
        setRotationLens2(0);
        return;
      }
    }

    if (value < -90) {
      if (lens === 1) {
        setRotationLens1Inp("-90");
        setRotationLens1(-90);
        return;
      } else {
        setRotationLens2Inp("-90");
        setRotationLens2(-90);
        return;
      }
    }

    let clampedValue = Math.round(value);

    const convertedValue =
      clampedValue <= 90 ? -clampedValue : 180 - clampedValue;

    if (lens === 1) {
      if (inputValue === "") {
        setRotationLens1(0);
        setRotationLens1Inp("");
      } else {
        setRotationLens1(convertedValue);
        setRotationLens1Inp(inputValue);
        gsap.to(lensRef1.current, {
          duration: 0.3,
          rotation: convertedValue,
          ease: "power2.inOut",
        });
      }
    } else {
      if (inputValue === "") {
        setRotationLens2(0);
        setRotationLens2Inp("");
      } else {
        setRotationLens2(convertedValue);
        setRotationLens2Inp(inputValue);
        gsap.to(lensRef2.current, {
          duration: 0.3,
          rotation: convertedValue,
          ease: "power2.inOut",
        });
      }
    }
  }, []);

  const handleFocus = useCallback((lens) => {
    setFocusState((prev) => ({ ...prev, [lens]: true }));
  }, []);

  const handleBlur = useCallback((lens) => {
    setFocusState((prev) => ({ ...prev, [lens]: false }));
  }, []);

  const handleMouseEnter = useCallback((lens) => {
    setHoverState((prev) => ({ ...prev, [lens]: true }));
  }, []);

  const handleMouseLeave = useCallback((lens) => {
    setHoverState((prev) => ({ ...prev, [lens]: false }));
  }, []);

  const getBorderStyle = useCallback(
    (lens) => {
      if (focusState[lens]) return "input-container-focused box-shadow";
      if (hoverState[lens]) return "input-container-focused";
      return "input-container";
    },
    [focusState, hoverState]
  );

  return (
    <Dialog
      header={
        lensOrientation === stabilisation ? t("Stabilisation") : t("Rotation")
      }
      visible={visible}
      onHide={onHide}
      draggable={false}
    >
      <div className="flex gap-2 justify-content-center align-items-center">
        {lenses.map(({ lens, label, rotation, rotationInp, lensRef }) => {
          return (
            <div
              key={lens}
              className={`lens-container-${lens} flex flex-column align-items-center`}
            >
              <div
                className="rotating-lens w-max border-circle "
                style={isMobile ? { width: "150px", height: "150px" } : { width: "114px", height: "114px" }}
              >
                <Lens
                  ref={lensRef}
                  style={isMobile ? { width: "140px", height: "140px" } : {}}
                  className="custom-tooltip"
                  data-pr-tooltip={t("LensTooltip")}
                  data-pr-position="right"
                  data-pr-at="right bottom"
                  data-pr-my="left center-55"
                />
                <Tooltip target=".custom-tooltip" />
                <p
                  className="absolute"
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {t(`${label}`)}
                </p>
              </div>
              <div
                className={`${getBorderStyle(
                  lens
                )} rotation-input flex align-items-center mt-2 border-round-md`}
                onMouseEnter={() => handleMouseEnter(lens)}
                onMouseLeave={() => handleMouseLeave(lens)}
              >
                <InputText
                  value={rotationInp}
                  onChange={(e) => handleInputChange(e, lens)}
                  onBlur={() => handleBlur(lens)}
                  onFocus={() => handleFocus(lens)}
                  className="input max-w-5rem border-round-left-md border-noround-right border-right-none p-inputtext-sm"
                  autoComplete="off"
                />
                <div className="text-sm pointer-events-none border-round-right-md border-left-none p-2">
                  °
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-content-end mt-3">
        <Button
          label={t("Apply")}
          onClick={onApplyClick}
          className=" dark-button"
        />
      </div>
    </Dialog>
  );
};

export default RotationModal;
