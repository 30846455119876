import { checkAxis } from "../helpers/check.js";
import { defaultFraction } from "../helpers/constant.js";
import { deg2rad, rad2deg } from "../helpers/convert.js";
import { formatSpheroCylinder } from "../helpers/format.js";

/********************************************************************
 * Function to calculate cross-cylinder parameters
 ********************************************************************/
export function calculateCrosscylinder({
    sphere1 = 0,
    cylinder1 = 0,
    axis1 = 0,
    sphere2 = 0,
    cylinder2 = 0,
    axis2 = 0,
    fraction = defaultFraction,
    sign = "",
}) {
    axis1 = checkAxis(axis1);
    axis2 = checkAxis(axis2);

    // Calculate vectors for both lenses
    const p11 = -0.5 * cylinder1 * Math.cos(2 * deg2rad(axis1));
    const p12 = -0.5 * cylinder1 * Math.sin(2 * deg2rad(axis1));
    const p13 = sphere1 + 0.5 * cylinder1;

    const p21 = -0.5 * cylinder2 * Math.cos(2 * deg2rad(axis2));
    const p22 = -0.5 * cylinder2 * Math.sin(2 * deg2rad(axis2));
    const p23 = sphere2 + 0.5 * cylinder2;

    // Combine vectors
    const p1 = p11 + p21;
    const p2 = p12 + p22;
    const p3 = p13 + p23;

    // Calculate new cylinder, sphere, and axis
    const cylinder = -2 * Math.sqrt(p1 * p1 + p2 * p2);
    const sphere = p3 - 0.5 * cylinder;
    const axis = cylinder !== 0 ? rad2deg(0.5 * Math.acos((-2 * p1) / cylinder)) : 0;
    const finalAxis = p2 < 0 ? 180 - checkAxis(axis) : checkAxis(axis);

    const format = formatSpheroCylinder({
        sphere,
        cylinder,
        axis: finalAxis,
        fraction,
        sign,
    });

    return {
        sphere,
        cylinder,
        axis: finalAxis,
        formattedSphere: format.sphere,
        formattedCylinder: format.cylinder,
        formattedAxis: format.axis,
    };
}

/********************************************************************
 * Function to calculate cross-cylinder component
 ********************************************************************/
export function calculateCrosscylinderComponent({
    sphere1 = 0,
    cylinder1 = 0,
    axis1 = 0,
    sphere3 = 0,
    cylinder3 = 0,
    axis3 = 0,
    fraction = defaultFraction,
    sign = "",
}) {
    axis1 = checkAxis(axis1);
    axis3 = checkAxis(axis3);

    // Calculate vector lens 1
    const p11 = -0.5 * cylinder1 * Math.cos(2 * deg2rad(axis1));
    const p12 = -0.5 * cylinder1 * Math.sin(2 * deg2rad(axis1));
    const p13 = sphere1 + 0.5 * cylinder1;

    // Calculate vector result lens (lens 3)
    const p31 = -0.5 * cylinder3 * Math.cos(2 * deg2rad(axis3));
    const p32 = -0.5 * cylinder3 * Math.sin(2 * deg2rad(axis3));
    const p33 = sphere3 + 0.5 * cylinder3;

    // Subtract vectors
    const p1 = p31 - p11;
    const p2 = p32 - p12;
    const p3 = p33 - p13;

    // Calculate new cylinder, sphere, and axis
    const cylinder = -2 * Math.sqrt(p1 * p1 + p2 * p2);
    const sphere = p3 - 0.5 * cylinder;
    const axis = cylinder !== 0 ? rad2deg(0.5 * Math.acos((-2 * p1) / cylinder)) : 0;
    const finalAxis = p2 < 0 ? 180 - checkAxis(axis) : checkAxis(axis);

    const format = formatSpheroCylinder({
        sphere,
        cylinder,
        axis: finalAxis,
        fraction,
        sign,
    });

    return {
        sphere,
        cylinder,
        axis: finalAxis,
        formattedSphere: format.sphere,
        formattedCylinder: format.cylinder,
        formattedAxis: format.axis,
    };
}