import React from "react";
import CardContainer from "../CardContainer";
import InputsContainer from "../InputsContainer";
import InputsComponent from "../InputsComponent";
import MaterialSelect from "../rgpCalculator/MaterialSelect";
import { useMediaQuery } from "react-responsive";
// import { useTranslation } from "react-i18next";

const ParametersInputs = ({
  control,
  errors,
  getValues,
  setValue,
  options,
  title,
  isRadiusNew1ODChanged,
  isRadiusNew1OSChanged,
  isRadiusNew2ODChanged,
  isRadiusNew2OSChanged,
  setIsRadiusNew1ODChanged,
  setIsRadiusNew1OSChanged,
  setIsRadiusNew2ODChanged,
  setIsRadiusNew2OSChanged,
  parametersODIndexes,
  materialODIndex,
  parametersOSIndexes,
  materialOSIndex,
  inputs,
  isOld,
  onToggle,
  isOpen,
  hideTitle,
}) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 979 });
  // const { t } = useTranslation();
  return (
    <CardContainer title={title} onToggle={onToggle} isOpen={isOpen === title} hideTitle={hideTitle} eye="OD">
      <div className="flex gap-2">
        {/* <div className="flex align-items-center justify-content-center eye-label text-sm border border-solid border-round-md">
          {t("OD")}
        </div> */}
        <div className={`flex flex-column gap-3 flex-1`}>
          <InputsContainer alowWrap>
            <InputsComponent
              inputs={inputs}
              eye="OD"
              control={control}
              errors={errors}
              className="radius"
              getValues={getValues}
              setValue={setValue}
              inputMode="decimal"
              formTransition={`${isTabletOrMobile ? "flex-grow-1" : ""}`}
              tabOrderIndex={parametersODIndexes}
              isRadiusNew1ODChanged={isRadiusNew1ODChanged}
              isRadiusNew1OSChanged={isRadiusNew1OSChanged}
              isRadiusNew2ODChanged={isRadiusNew2ODChanged}
              isRadiusNew2OSChanged={isRadiusNew2OSChanged}
              setIsRadiusNew1ODChanged={setIsRadiusNew1ODChanged}
              setIsRadiusNew1OSChanged={setIsRadiusNew1OSChanged}
              setIsRadiusNew2ODChanged={setIsRadiusNew2ODChanged}
              setIsRadiusNew2OSChanged={setIsRadiusNew2OSChanged}
            />
            {options.length !== 0 && (
              <MaterialSelect
                name={isOld ? "materialOldOD" : "materialNewOD"}
                control={control}
                tabOrderIndex={materialODIndex}
                options={options}
                setValue={setValue}
              ></MaterialSelect>
            )}
          </InputsContainer>
        </div>
      </div>
      <div className="flex gap-2">
        {/* <div className="flex align-items-center justify-content-center eye-label text-sm border border-solid border-round-md">
          {t("OS")}
        </div> */}
        <div className={`flex flex-column gap-3 flex-1`}>
          <InputsContainer alowWrap>
            <InputsComponent
              inputs={inputs}
              eye="OS"
              control={control}
              errors={errors}
              className="radius"
              getValues={getValues}
              setValue={setValue}
              inputMode="decimal"
              formTransition={`${isTabletOrMobile ? "flex-grow-1" : ""}`}
              tabOrderIndex={parametersOSIndexes}
              isRadiusNew1ODChanged={isRadiusNew1ODChanged}
              isRadiusNew1OSChanged={isRadiusNew1OSChanged}
              isRadiusNew2ODChanged={isRadiusNew2ODChanged}
              isRadiusNew2OSChanged={isRadiusNew2OSChanged}
              setIsRadiusNew1ODChanged={setIsRadiusNew1ODChanged}
              setIsRadiusNew1OSChanged={setIsRadiusNew1OSChanged}
              setIsRadiusNew2ODChanged={setIsRadiusNew2ODChanged}
              setIsRadiusNew2OSChanged={setIsRadiusNew2OSChanged}
            ></InputsComponent>
            {options.length !== 0 && (
              <MaterialSelect
                name={isOld ? "materialOldOS" : "materialNewOS"}
                control={control}
                tabOrderIndex={materialOSIndex}
                options={options}
                setValue={setValue}
              ></MaterialSelect>
            )}
          </InputsContainer>
        </div>
      </div>
    </CardContainer>
  );
};

export default ParametersInputs;
