import React from "react";
import CardContainer from "../CardContainer";
import InputsContainer from "../InputsContainer";
import InputsComponent from "../InputsComponent";
import { useTranslation } from "react-i18next";
import {
  overRefractionAxisInput,
  overRefractionSphereCylinderInputs,
} from "../../constants/constants";

const OverRefractionInputs = ({
  control,
  errors,
  sphereCylinderODIndex,
  axisODIndex,
  sphereCylinderOSIndex,
  axisOSIndex,
  onToggle,
  isOpen,
}) => {
  const { t } = useTranslation();
  return (
    <CardContainer title={t("Over-refraction")}
    onToggle={onToggle}
      isOpen={isOpen === t("Over-refraction")}>
      <InputsContainer description={t("OD")}>
        <InputsComponent
          inputs={overRefractionSphereCylinderInputs}
          eye="OD"
          control={control}
          errors={errors}
          className="sphere-cylinder"
          tabOrderIndex={sphereCylinderODIndex}
        ></InputsComponent>
        <InputsComponent
          inputs={overRefractionAxisInput}
          eye="OD"
          control={control}
          errors={errors}
          className="axis"
          tabOrderIndex={axisODIndex}
          inputMode="numeric"
        ></InputsComponent>
      </InputsContainer>
      <InputsContainer description={t("OS")}>
        <InputsComponent
          inputs={overRefractionSphereCylinderInputs}
          eye="OS"
          control={control}
          errors={errors}
          className="sphere-cylinder"
          tabOrderIndex={sphereCylinderOSIndex}
        ></InputsComponent>
        <InputsComponent
          inputs={overRefractionAxisInput}
          eye="OS"
          control={control}
          errors={errors}
          className="axis"
          tabOrderIndex={axisOSIndex}
          inputMode="numeric"
        ></InputsComponent>
      </InputsContainer>
    </CardContainer>
  );
};

export default OverRefractionInputs;
